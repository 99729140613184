/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormContext } from "react-hook-form";
import { WorkflowDataType } from "~/views/workflows/constants";
import { WorkflowFormData } from "~/views/workflows/types";
import { parseFieldErrors } from "./utils";

function getEnterSelectDataComponentSwitch(
  selectDataComponent: WorkflowDataType | undefined
) {
  if (selectDataComponent === undefined) {
    return false;
  }

  if (
    [WorkflowDataType.ANOMALY_COSTS, WorkflowDataType.BUDGETS].includes(
      selectDataComponent
    )
  ) {
    return true;
  }

  return [
    WorkflowDataType.COSTS,
    WorkflowDataType.KPI,
    WorkflowDataType.UNALLOCATED_RESOURCES,
    WorkflowDataType.SAVING_SUGGESTIONS,
  ].includes(selectDataComponent);
}

type UseDescriptionFieldsSectionProps = {
  selectDataComponent: WorkflowDataType | undefined;
};

export function useDescriptionFieldsSection({
  selectDataComponent,
}: UseDescriptionFieldsSectionProps) {
  const {
    formState: { errors: fieldErrors },
    register,
  } = useFormContext<WorkflowFormData>();
  const { shortDescriptionError, descriptionError } =
    parseFieldErrors(fieldErrors);

  const shouldEnterSelectDataComponentSwitch =
    getEnterSelectDataComponentSwitch(selectDataComponent);

  return {
    shortDescriptionError,
    descriptionError,
    register,
    shouldEnterSelectDataComponentSwitch,
  };
}
