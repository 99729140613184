/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { When } from "react-if";
import {
  Button,
  FlexContainer,
  Message,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { useDisclosure } from "~/hooks";
import { CloudAccountStatus } from "~/tools";
import { BillingDataSidebar } from "../../BillingData";
import { CloudProviderType, Provider, ProviderTab } from "../../types";
import { isKubernetesOrOpenshift } from "../../utils";
import { Account } from "../types";
import { HybridCloudDocumentationSidebar } from "./HybridCloudDocumentationSidebar";
import { HybridCloudDownloadButton } from "./HybridCloudDownloadButton";
import { HybridOnboardSidebar } from "./HybridOnboardSidebar";

type Props = {
  account: Account;
  openCredentialsUpdateSteps: () => void;
};

export function DetailsNotification({
  account,
  openCredentialsUpdateSteps,
}: Props) {
  const [show, setShow] = useState(true);

  const {
    open: openBillingDataSidebar,
    close: closeBillingDataSidebar,
    isOpen: isOpenBillingDataSidebar,
  } = useDisclosure();

  const {
    open: openDocumentationSidebar,
    close: closeDocumentationSidebar,
    isOpen: isOpenDocumentationSidebar,
  } = useDisclosure();

  const {
    open: openOnboardSidebar,
    close: closeOnboardSidebar,
    isOpen: isOpenOnboardSidebar,
  } = useDisclosure();

  const notificationInfo = getNotificationInfo(
    account.cloudStatus,
    account.providerType
  );

  const showCloseButton = [
    CloudAccountStatus.BillingOnly,
    CloudAccountStatus.ApiOnly,
  ].includes(account.cloudStatus);

  return (
    <>
      <When
        condition={
          show === true && account.cloudStatus !== CloudAccountStatus.FullAccess
        }
      >
        <Message status={notificationInfo.color} image={notificationInfo.image}>
          <FlexContainer
            direction="row"
            justifyContent="space-between"
            alignItems="start"
            css={{ width: "100%" }}
          >
            <div>
              <Text
                weight="medium"
                color={notificationInfo.titleColor}
                mb={theme.spacing.spacing00}
              >
                {notificationInfo.title}
              </Text>
              <Text
                mt={theme.spacing.spacing02}
                mb={theme.spacing.spacing00}
                color={theme.color.text.text02}
              >
                {notificationInfo.description}
              </Text>
              <Spacer height={theme.spacing.spacing04} />
              <FlexContainer>
                <When
                  condition={notificationInfo.buttonSet === "updateCredentials"}
                >
                  <Button
                    size="md"
                    status={notificationInfo.color}
                    icon="settings"
                    onClick={openCredentialsUpdateSteps}
                  >
                    Update Credentials
                  </Button>
                </When>
                <When condition={notificationInfo.buttonSet === "addBilling"}>
                  <Button
                    size="md"
                    status={notificationInfo.color}
                    icon="costSettings"
                    onClick={openBillingDataSidebar}
                  >
                    Add Billing Data
                  </Button>
                </When>
                <When
                  condition={notificationInfo.buttonSet === "onboardAccount"}
                >
                  <Button
                    size="md"
                    status={notificationInfo.color}
                    icon="settings"
                    onClick={openOnboardSidebar}
                  >
                    Onboard Account
                  </Button>
                </When>
                <When condition={notificationInfo.buttonSet === "noData"}>
                  <HybridCloudDownloadButton
                    account={account}
                    size="md"
                    status="discovery"
                  />
                  <Button
                    size="md"
                    ml={theme.spacing.spacing04}
                    severity="low"
                    icon="info"
                    onClick={openDocumentationSidebar}
                  >
                    Documentation
                  </Button>
                </When>
              </FlexContainer>
            </div>
            <When condition={showCloseButton}>
              <Button
                size="sm"
                severity="low"
                icon="close"
                onClick={() => setShow(false)}
              />
            </When>
          </FlexContainer>
        </Message>
        <Spacer margin={theme.spacing.spacing04} />
        <BillingDataSidebar
          isOpen={isOpenBillingDataSidebar}
          provider={billingProvider(account.provider)}
          onClose={closeBillingDataSidebar}
        />
        <HybridCloudDocumentationSidebar
          account={account}
          isOpen={isOpenDocumentationSidebar}
          onClose={closeDocumentationSidebar}
        />
        <HybridOnboardSidebar
          account={account}
          isOpen={isOpenOnboardSidebar}
          onClose={closeOnboardSidebar}
        />
      </When>
    </>
  );
}

type NotificationInfo = {
  title: string;
  titleColor: string;
  description: string;
  color: "danger" | "discovery" | "warning";
  image: "danger" | "help" | "warning";
  buttonSet:
    | "addBilling"
    | "noButtons"
    | "noData"
    | "onboardAccount"
    | "updateCredentials";
};

function getNotificationInfo(
  cloudStatus: CloudAccountStatus,
  providerType: CloudProviderType
): NotificationInfo {
  switch (cloudStatus) {
    case CloudAccountStatus.NoAccess:
      if (isKubernetesOrOpenshift(providerType)) {
        return {
          title: "No Access",
          titleColor: theme.color.text.error,
          description:
            "This cloud account was automatically discovered by scanning public cloud accounts. To scan resources running in your cluster please proceed with onboarding procedure.",
          color: "danger",
          image: "danger",
          buttonSet: "onboardAccount",
        };
      }

      return {
        title: "No Access",
        titleColor: theme.color.text.error,
        description:
          "We're sorry, but the system is currently unable to access the cloud API with the provided credentials. Please update your credentials to gain access to the list of cloud resources.",
        color: "danger",
        image: "danger",
        buttonSet: "updateCredentials",
      };

    case CloudAccountStatus.BillingOnly:
      return {
        title: "Update Credentials",
        titleColor: theme.color.text.warning,
        description:
          "Please note that for this account, the system only has access to billing data. As a result, the Cloud Resource Metadata information cannot be displayed.",
        color: "warning",
        image: "warning",
        buttonSet: "updateCredentials",
      };

    case CloudAccountStatus.ApiOnly:
      return {
        title: "Add Billing Data",
        titleColor: theme.color.text.warning,
        description:
          "Please note that for this account, the system only has access to the Cloud Estate API and not to the corresponding billing data. As a result, the system will not be able to display any information about the costs associated with this account.",
        color: "warning",
        image: "warning",
        buttonSet: "addBilling",
      };

    case CloudAccountStatus.NoData:
      return {
        title: "No Data",
        titleColor: theme.color.text.text01,
        description:
          "No data has been received recently. Please check that scanner is deployed and running successfully.",
        color: "discovery",
        image: "help",
        buttonSet: "noData",
      };

    default:
      return {
        title: "Unknown status",
        titleColor: theme.color.text.error,
        description: "Unknown cloud account status",
        color: "danger",
        image: "danger",
        buttonSet: "noButtons",
      };
  }
}

const billingProvider = (provider: Provider) => {
  switch (provider) {
    case Provider.Aws:
      return ProviderTab.AWS;
    case Provider.Gcp:
      return ProviderTab.GCP;
    case Provider.Azure:
      return ProviderTab.AZURE;
    default:
      return undefined;
  }
};
