/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { isNotNil, isNotEmpty } from "~/tools";
import { CloudWasteOption, PolicyOption } from "../types";

export function findByCategory<T extends { category: string }>(
  collection: T[] | null,
  category: string
) {
  return collection?.find((item) => item.category === category);
}

export function findById<T extends { id: string }>(
  collection: T[] | null,
  id: string
) {
  return collection?.find((item) => item.id === id);
}

export function generateSelectedCategories(
  selectedPoliciesIds: string[],
  categories: CloudWasteOption[]
) {
  const policies = generateSelectedPolicies(selectedPoliciesIds);
  return categories
    .map((category) =>
      areAllPoliciesSelected(policies, category) ? category : undefined
    )
    .filter(isNotNil);
}

export function generateSelectedPolicies(selectedPoliciesIds: string[]) {
  return selectedPoliciesIds.map((selectedPolicyId) => ({
    id: selectedPolicyId,
    name: "",
  }));
}

export function areAllPoliciesSelected(
  selectedPolicies: PolicyOption[],
  parentCategory: CloudWasteOption,
  policy?: PolicyOption
) {
  return parentCategory?.policies.every((parentPolicy) => {
    const selections = policy
      ? [...selectedPolicies, policy]
      : selectedPolicies;
    return selections.some(
      (selectedPolicy) => selectedPolicy.id === parentPolicy.id
    );
  });
}

export function filterUniquePolicies(policies: PolicyOption[]) {
  return policies.filter(
    (value, index, self) =>
      self.findIndex(({ id }) => id === value.id) === index
  );
}

export function filterCategoriesByName(
  name: string,
  categories: CloudWasteOption[]
) {
  return categories
    .map((category) => {
      const filteredPolicies = category.policies.filter((policy) =>
        policy.name.toLowerCase().includes(name.toLowerCase())
      );
      return isNotEmpty(filteredPolicies)
        ? {
            ...category,
            policies: filteredPolicies,
          }
        : undefined;
    })
    .filter(isNotNil);
}

export function hasPolicies(policies: PolicyOption[]) {
  return isNotNil(policies) && isNotEmpty(policies);
}

export function getPoliciesIds(
  category: CloudWasteOption,
  policy?: PolicyOption
) {
  return (
    category.policies
      .map((parentPolicy) =>
        parentPolicy && parentPolicy.id !== policy?.id
          ? parentPolicy.id
          : undefined
      )
      .filter(isNotNil) ?? []
  );
}

export function getFilteredCategoriesToSelect(
  filteredCategories: CloudWasteOption[],
  categories: CloudWasteOption[]
) {
  return filteredCategories
    .map((filteredCategory) => {
      const parentCategory = categories.find(
        ({ category }) => category === filteredCategory.category
      );

      return parentCategory &&
        parentCategory.policies.length === filteredCategory.policies.length
        ? filteredCategory
        : undefined;
    })
    .filter(isNotNil);
}
