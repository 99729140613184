/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { When } from "react-if";
import { Button, Sidebar, theme } from "@nordcloud/gnui";
import { WorkflowEmailOptionsMessageType } from "~/generated/graphql";
import { FormGroup } from "~/components";
import { noop } from "~/tools";
import { WorkflowFields } from "~/views/workflows/AddWorkflow/constants";
import { previewMessage, WorkflowDataType } from "../../constants";
import {
  WorkflowFormData,
  getDefaultValues,
  WorkflowFormMode,
} from "../../types";
import { getDefaultValues as getDefaultWorkflowValues } from "../../WorkflowDetails/helpers";
import { WorkflowProps } from "../../WorkflowPropsMapping";
import { BudgetsConditions } from "../BudgetsConditions";
import {
  getDefaultEmailMessageRadioValue,
  getPredefinedSubjectAndMessage,
} from "../utils";
import { MailTo } from "./MailTo";

type Props = {
  isOpen: boolean;
  workflow?: WorkflowProps;
  onClose: () => void;
  onSave?: () => void;
  formMode: WorkflowFormMode;
};

export function EmailSettingsForm({
  isOpen,
  workflow,
  onClose,
  onSave = noop,
  formMode,
}: Props) {
  const {
    trigger,
    formState: { errors },
    setValue,
    getValues,
    watch,
    resetField,
  } = useFormContext<WorkflowFormData>();
  const [email, emailSubmissionSnapshot, selectDataComponent] = watch([
    "email",
    "emailSubmissionSnapshot",
    "selectDataComponent",
  ]);
  const [selectedRadioValue, setSelectedRadioValue] = useState(
    getDefaultEmailMessageRadioValue(selectDataComponent, workflow)
  );
  const [isRegularEmail, setIsRegularEmail] = useState(
    getValues(["email.mailTo", "email.sendToAdditionalUsers"]).some(Boolean)
  );

  const [showToggleError, setShowToggleError] = useState(false);

  const isReadMode = formMode === "read";
  const shouldSendToContacts = getValues("email.sendToContactPersons");

  useEffect(() => {
    const shouldSendToAdditionalUsers = Boolean(email.sendToAdditionalUsers);
    setIsRegularEmail(shouldSendToAdditionalUsers);
  }, [email]);

  useEffect(() => {
    const defaultRadioValue = getDefaultEmailMessageRadioValue(
      selectDataComponent,
      workflow
    );
    setSelectedRadioValue(defaultRadioValue);
  }, [selectDataComponent, workflow]);

  const handleSaveButtonClick = useCallback(async () => {
    const { subject, message } =
      getPredefinedSubjectAndMessage(selectedRadioValue);

    if (
      selectDataComponent !== WorkflowDataType.UNALLOCATED_RESOURCES &&
      !isRegularEmail &&
      !shouldSendToContacts
    ) {
      setShowToggleError(true);
      trigger("budgetsOptions");
      return;
    }

    setValue("email", {
      subject,
      message,
      sendToContactPersons: getValues("email.sendToContactPersons"),
      sendToAdditionalUsers: getValues("email.sendToAdditionalUsers"),
      mailTo: getValues("email.mailTo"),
      messageType: getMessageType({
        shouldSendToContacts,
        isRegularEmail,
        selectedRadioValue,
      }),
    });

    const isValid = await trigger(["email", "budgetsOptions.costGtBudget"]);

    if (isValid) {
      setValue("emailSubmissionSnapshot", JSON.stringify(email), {
        shouldDirty: true,
      });
      setValue("emailSaved", true);
      onSave();
    }
  }, [
    email,
    getValues,
    onSave,
    selectedRadioValue,
    setValue,
    trigger,
    isRegularEmail,
    shouldSendToContacts,
  ]);

  const handleClose = useCallback(() => {
    if (isReadMode) {
      onClose();
      return;
    }

    if (formMode === "add") {
      resetField("email", {
        defaultValue: emailSubmissionSnapshot
          ? JSON.parse(emailSubmissionSnapshot)
          : getDefaultValues().email,
      });
    }

    if (formMode === "edit") {
      setSelectedRadioValue(
        getDefaultEmailMessageRadioValue(selectDataComponent, workflow)
      );

      if (emailSubmissionSnapshot) {
        resetField("email", {
          defaultValue: JSON.parse(emailSubmissionSnapshot),
        });
      } else if (selectDataComponent === workflow?.selectData.type) {
        resetField("email", {
          defaultValue: workflow && getDefaultWorkflowValues(workflow).email,
        });
      } else {
        resetField("email", {
          defaultValue: getDefaultValues().email,
        });
      }
    }

    resetField("budgetsOptions.costGtBudget");
    resetField("budgetsOptions.forecastGtBudget");

    onClose();
  }, [
    emailSubmissionSnapshot,
    formMode,
    onClose,
    resetField,
    selectDataComponent,
    workflow,
  ]);

  const previewImage =
    selectDataComponent === "UNALLOCATED_RESOURCES"
      ? previewMessage[selectDataComponent as WorkflowDataType]
      : undefined;

  const emailErrors = errors[WorkflowFields.EMAIL];

  return (
    <Sidebar
      title="Email Settings"
      isOpen={isOpen}
      width="42rem"
      onClick={handleClose}
    >
      <When condition={selectDataComponent === WorkflowDataType.BUDGETS}>
        <BudgetsConditions shouldBeSelected isReadMode={isReadMode} />
      </When>
      <FormGroup error={emailErrors?.mailTo}>
        <MailTo
          isReadMode={isReadMode}
          selectedRadioValue={selectedRadioValue}
          setSelectedRadioValue={setSelectedRadioValue}
          showToggleError={showToggleError}
          setShowToggleError={setShowToggleError}
          isRegularEmail={isRegularEmail}
          isPredefinedEmails={shouldSendToContacts}
          type={selectDataComponent}
          preview={previewImage}
        />
      </FormGroup>
      <When condition={!isReadMode}>
        <Button
          type="button"
          mt={theme.spacing.spacing04}
          onClick={handleSaveButtonClick}
        >
          Apply
        </Button>
      </When>
    </Sidebar>
  );
}

const getMessageType = ({
  isRegularEmail,
  selectedRadioValue,
  shouldSendToContacts,
}: {
  isRegularEmail: boolean;
  selectedRadioValue: WorkflowEmailOptionsMessageType;
  shouldSendToContacts: boolean;
}) => {
  if (isRegularEmail) {
    return selectedRadioValue;
  }

  return shouldSendToContacts
    ? WorkflowEmailOptionsMessageType.Undefined
    : selectedRadioValue;
};
