/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export enum SortByOrder {
  Ascending = "ASCENDING",
  Descending = "DESCENDING",
}

export enum SortByValue {
  ApiKeyDescription = "API_KEY_DESCRIPTION",
  CreationDate = "CREATION_DATE",
  ExpirationDate = "EXPIRATION_DATE",
  LastLoginDate = "LAST_LOGIN_DATE",
  UserEmailId = "USER_EMAIL_ID",
}

export type SortKeysUsers = {
  field: SortByValue;
  order: SortByOrder;
};

export type QuerySort = SortKeysUsers & {
  page: number;
  limit: number;
  query: string;
  tab: number;
};
