// Source: https://github.com/DataDog/rum-react-integration-examples/tree/master/src/Router

import {
  isValidElement,
  useRef,
  FunctionComponent,
  ComponentClass,
} from "react";
import type { RouteProps, RouteComponentProps } from "react-router-dom";
import { useDatadogRUM } from "../useDatadogRum";

type RumRouteComponentType =
  | RouteProps["children"]
  | RouteProps["component"]
  | RouteProps["render"];

function isClassComponent(
  component: RumRouteComponentType
): component is ComponentClass {
  return (
    typeof component === "function" &&
    Boolean(component.prototype?.isReactComponent) // eslint-disable-line @typescript-eslint/no-unsafe-member-access
  );
}

function isFunctionComponent(
  component: RumRouteComponentType
): component is FunctionComponent<unknown> {
  return (
    typeof component === "function" &&
    Object.hasOwn(component, "props") &&
    isValidElement(component)
  );
}

function isReactRouterComponent(
  component: RumRouteComponentType
): component is RouteProps["component"] {
  return isClassComponent(component) || isFunctionComponent(component);
}

export const withRum =
  (component: RumRouteComponentType) => (props: RouteComponentProps) => {
    const ddRum = useDatadogRUM();

    useRef(
      (() => {
        if (!component) {
          return;
        }

        if (ddRum?.startView == null) {
          return;
        }

        const isManualTracking =
          // @ts-expect-error types are not yet updated inside library
          ddRum?.getInitConfiguration()?.trackViewsManually === true;

        if (!isManualTracking) {
          console.warn(
            "Datadog RUM: The trackViewsManually flag in RUM initialization must be set to %ctrue%c.",
            "color:green",
            "color:default"
          );

          return;
        }

        ddRum.startView(props.match.path);
      })()
    );

    if (!component) {
      return <>{component}</>;
    }

    if (isReactRouterComponent(component)) {
      const Component = component;
      return <Component {...props} />;
    }

    if (component instanceof Function) {
      return <>{component(props)}</>;
    }

    return <>{component}</>;
  };
