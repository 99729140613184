/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { PathName } from "@nordcloud/gnui/dist/components/svgicon/paths";
import { SVGIcon, Text, theme, FlexContainer } from "@nordcloud/gnui";
import {
  WorkflowFrequency,
  WorkflowTrigger,
  WorkflowTriggerType,
} from "~/generated/graphql";
import { ComponentGroupColorMap, ComponentGroup } from "../constants";
import { getScheduleSummaryTextFromWorkflowData } from "../utils";
import { ComponentIconWrapper } from "../WorkflowCanvas";

type Props = {
  triggers: WorkflowTrigger[];
  active: boolean;
};

export function TriggerList({ triggers, active }: Props) {
  const opacity = active ? 1 : 0.6;

  return (
    <FlexContainer css={{ opacity, height: "100%", width: "100%" }}>
      {triggers.map((trigger) => {
        if (isScheduler(trigger)) {
          return (
            <SchedulerTrigger
              key={trigger.type}
              schedulerOptions={trigger.schedulerOptions}
            />
          );
        }
        if (isManual(trigger)) {
          return <ManualTrigger key={trigger.type} />;
        }
        if (isScan(trigger)) {
          return <ScanTrigger key={trigger.type} />;
        }

        return null;
      })}
    </FlexContainer>
  );
}

type TriggerScheduler = {
  type: WorkflowTriggerType.Scheduler;
  schedulerOptions: {
    frequency: WorkflowFrequency;
    startDate: string;
    byWeekday?: number[];
    endDate?: string;
    interval?: number;
  };
};

type TriggerManual = {
  type: WorkflowTriggerType.Manual;
};

type TriggerScan = {
  type: WorkflowTriggerType.Scan;
};

const isScheduler = (trigger: WorkflowTrigger): trigger is TriggerScheduler => {
  return trigger.type === WorkflowTriggerType.Scheduler;
};

const isManual = (trigger: WorkflowTrigger): trigger is TriggerManual => {
  return trigger.type === WorkflowTriggerType.Manual;
};

const isScan = (trigger: WorkflowTrigger): trigger is TriggerScan => {
  return trigger.type === WorkflowTriggerType.Scan;
};

type SchedulerTriggerProps = Pick<TriggerScheduler, "schedulerOptions">;

function SchedulerTrigger({ schedulerOptions }: SchedulerTriggerProps) {
  const summaryText = React.useMemo(
    () => getScheduleSummaryTextFromWorkflowData(schedulerOptions),
    [schedulerOptions]
  );

  return (
    <Trigger icon="eventSchedule" title="Scheduler" subtitle={summaryText} />
  );
}

function ManualTrigger() {
  return <Trigger subtitle="Force run" title="Manual" icon="thunder" />;
}

function ScanTrigger() {
  return (
    <Trigger
      subtitle="When daily scanning of accounts is completed"
      title="Scan Completed"
      icon="zoomArea"
    />
  );
}

type TriggerProps = {
  icon: PathName;
  title: string;
  subtitle: string;
};

function Trigger({ icon, title, subtitle }: TriggerProps) {
  return (
    <FlexContainer css={{ width: "100%" }}>
      <ComponentIconWrapper
        color={ComponentGroupColorMap[ComponentGroup.TRIGGER]}
      >
        <SVGIcon name={icon} size="smd" color="white" />
      </ComponentIconWrapper>
      <div css={{ width: "100%" }}>
        <Text mb={0} pl={theme.spacing.spacing02}>
          {title}
        </Text>
        <Text
          size="sm"
          color={theme.color.text.text02}
          mb={0}
          pl={theme.spacing.spacing02}
        >
          {subtitle}
        </Text>
      </div>
    </FlexContainer>
  );
}
