/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { Text } from "@nordcloud/gnui";
import { EstatePeriod, TableData } from "~/components";
import { isEmpty } from "~/tools";
import { EstateTotalsCostTableBody } from "./EstateTotalsCostTableBody";
import { Th, StyledTable } from "./styles";

type Props = {
  data: TableData[];
  columns: ColumnDef<TableData>[];
  period: EstatePeriod;
};

export function EstateTotalsCostTable({ data, columns, period }: Props) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  if (isEmpty(table.getRowModel().rows)) {
    return <Text>No data available.</Text>;
  }

  const columnSize = table.getAllColumns().length;

  return (
    <StyledTable mb="0">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={`header-row-${headerGroup.id}`}>
            {headerGroup.headers.map((header) => (
              <Th
                key={header.id}
                colSpan={header.colSpan}
                wide={columnSize < 10}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </Th>
            ))}
          </tr>
        ))}
      </thead>
      <EstateTotalsCostTableBody
        rows={table.getRowModel().rows}
        period={period}
      />
    </StyledTable>
  );
}
