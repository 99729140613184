/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  AzureBillingDataDocument,
  AzureBillingDataQuery,
  AzureBillingDataQueryVariables,
  useRemoveAzureBillingConfigMutation,
} from "~/generated/graphql";

export function useDeleteAzureBillingData() {
  const [deleteBillingDataItem, { client, ...mutationState }] =
    useRemoveAzureBillingConfigMutation();

  const removeAzureBillingData = async (data: {
    capacitorCustomerId: string;
  }) => {
    const { data: removeResponse } = await deleteBillingDataItem({
      variables: { capacitorCustomerId: data.capacitorCustomerId },
    });

    if (removeResponse?.removeAzureBillingConfig) {
      client.cache.updateQuery<
        AzureBillingDataQuery,
        AzureBillingDataQueryVariables
      >(
        {
          query: AzureBillingDataDocument,
        },
        (billingData) => ({
          azureBillingData: (billingData?.azureBillingData ?? []).filter(
            (item) => item?.capacitorCustomerId !== data.capacitorCustomerId
          ),
        })
      );
    }
  };

  return [removeAzureBillingData, mutationState] as const;
}
