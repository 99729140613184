/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { UseFormSetValue } from "react-hook-form";
import { isSomeEnum } from "~/tools";
import { ComponentType, WorkflowDataType } from "~/views/workflows/constants";
import { WorkflowFormData } from "~/views/workflows/types";

export const isSelectDataType = isSomeEnum(WorkflowDataType);

const ShortDescriptions: { [key in ComponentType]?: string } = {
  ANOMALY_COSTS: "Anomaly Costs",
  BUDGETS: "Budgets",
  COSTS: "Costs",
  KPI: "KPIs",
  UNALLOCATED_RESOURCES: "New Unallocated Resources",
  SAVING_SUGGESTIONS: "Savings Suggestions",
};

export function setServiceNowShortDescription(
  setValue: UseFormSetValue<WorkflowFormData>,
  componentType: ComponentType
) {
  const shortDescription = ShortDescriptions[componentType];
  if (shortDescription === undefined) {
    return;
  }

  setValue("serviceNow.shortDescription", shortDescription, {
    shouldDirty: true,
  });
}
