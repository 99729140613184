/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  AzureBillingDataDocument,
  AzureBillingDataQuery,
  AzureBillingDataQueryVariables,
  useAddAzureBillingConfigMutation,
} from "~/generated/graphql";
import { ERROR_TEXT } from "~/constants";
import { FormData } from "~/views/cloudAccounts/BillingData/azure/validators";

export function useAddAzureBillingData() {
  const [addAzureCapacity, { client, ...mutationState }] =
    useAddAzureBillingConfigMutation();

  const addAzureBillingData = async (data: FormData) => {
    const response = await addAzureCapacity({ variables: { input: data } });

    if (response.data?.addAzureBillingConfig?.success) {
      client.cache.updateQuery<
        AzureBillingDataQuery,
        AzureBillingDataQueryVariables
      >(
        {
          query: AzureBillingDataDocument,
        },
        (billingData) => ({
          azureBillingData: [
            ...(billingData?.azureBillingData ?? []),
            {
              capacitorCustomerId: data.enrollmentAccessKey,
              enrollmentNumber: data.enrollmentNumber,
              isActive: false,
              isOnboarding: false,
            },
          ],
        })
      );

      return;
    }

    throw new Error(ERROR_TEXT.default);
  };

  return [addAzureBillingData, mutationState] as const;
}
