/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChangeEvent, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import {
  Input,
  Label,
  Spacer,
  theme,
  Text,
  Button,
  FlexContainer,
  List,
  Switch,
  Upload,
} from "@nordcloud/gnui";
import { ExternalLink, FormGroup } from "~/components";
import { showError } from "~/services/toast";
import { isNotEmpty } from "~/tools";
import {
  FormData,
  GcpFormFields,
} from "~/views/cloudAccounts/BillingData/gcp/types";
import { GcpFormSchema } from "~/views/cloudAccounts/BillingData/gcp/validators";
import { FormHint } from "../../components/FormHint";
import { GcpJsonData } from "../../types";

type Props = {
  onCloseForm: () => void;
  onSubmit: SubmitHandler<FormData>;
  defaultValues?: Partial<FormData>;
  disabledFields?: (keyof FormData)[];
};

export function GcpForm({
  onSubmit,
  onCloseForm,
  defaultValues,
  disabledFields = [],
}: Props) {
  const [filename, setFilename] = useState("");

  const formMethods = useForm<FormData>({
    resolver: yupResolver(GcpFormSchema),
    defaultValues: { ...defaultValues },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = formMethods;

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    callback: (event: unknown) => void
  ) => {
    const fileList = event.target.files ?? [];
    if (fileList instanceof FileList && fileList.length > 0) {
      setFilename(fileList[0].name);
    }

    callback(fileList);
  };

  const handleUpload = async (
    data: Omit<FormData, GcpFormFields.SERVICE_ACCOUNT_KEY> & {
      serviceAccountKey: FileList;
    }
  ) => {
    try {
      const json = await readFile<GcpJsonData>(data.serviceAccountKey[0]);

      onSubmit({
        ...data,
        serviceAccountKey: JSON.stringify(json),
      });
    } catch {
      showError(ERROR_READ_FILE);
      setFilename("");
    }
  };

  const uploadPlaceholder = isNotEmpty(filename)
    ? filename
    : UPLOAD_PLACEHOLDER_TEXT;

  return (
    <FormProvider {...formMethods}>
      <form
        css={{ width: "100%" }}
        id="gcp-add-form"
        onSubmit={handleSubmit(handleUpload)}
      >
        <FormHints />
        <Spacer height={theme.spacing.spacing02} />
        <FormGroup error={errors[GcpFormFields.ORGANIZATION_ID]}>
          <Label
            required
            htmlFor={GcpFormFields.ORGANIZATION_ID}
            name="Organization ID"
          />
          <Input
            id={GcpFormFields.ORGANIZATION_ID}
            disabled={disabledFields.includes(GcpFormFields.ORGANIZATION_ID)}
            {...register(GcpFormFields.ORGANIZATION_ID)}
            placeholder="e.g., 1234567890"
          />
        </FormGroup>
        <FormGroup error={errors[GcpFormFields.SERVICE_ACCOUNT_KEY]}>
          <Label
            required
            htmlFor={GcpFormFields.SERVICE_ACCOUNT_KEY}
            name="Service Account Key"
          />
          <Controller
            name={GcpFormFields.SERVICE_ACCOUNT_KEY}
            control={control}
            rules={{ required: "This field is required." }}
            render={({ field: { onChange } }) => (
              <Upload
                id={GcpFormFields.SERVICE_ACCOUNT_KEY}
                placeholder={uploadPlaceholder}
                onChange={(event) => handleInputChange(event, onChange)}
              />
            )}
          />
        </FormGroup>
        <FormGroup error={errors[GcpFormFields.PROJECT_ID]}>
          <Label
            required
            htmlFor={GcpFormFields.PROJECT_ID}
            name="Project Name"
          />
          <Input
            id={GcpFormFields.PROJECT_ID}
            disabled={disabledFields.includes(GcpFormFields.PROJECT_ID)}
            {...register(GcpFormFields.PROJECT_ID)}
            placeholder="e.g., my-project"
          />
        </FormGroup>
        <FormGroup error={errors[GcpFormFields.BIG_QUERY_DATASET_NAME]}>
          <Label
            required
            htmlFor={GcpFormFields.BIG_QUERY_DATASET_NAME}
            name="BigQuery Dataset Name"
          />
          <Input
            id={GcpFormFields.BIG_QUERY_DATASET_NAME}
            disabled={disabledFields.includes(
              GcpFormFields.BIG_QUERY_DATASET_NAME
            )}
            {...register(GcpFormFields.BIG_QUERY_DATASET_NAME)}
            placeholder="e.g., my_dataset"
          />
        </FormGroup>
        <FormGroup error={errors[GcpFormFields.BIG_QUERY_TABLE_NAME]}>
          <Label
            required
            htmlFor={GcpFormFields.BIG_QUERY_TABLE_NAME}
            name="BigQuery Table Name"
          />
          <Input
            id={GcpFormFields.BIG_QUERY_TABLE_NAME}
            disabled={disabledFields.includes(
              GcpFormFields.BIG_QUERY_TABLE_NAME
            )}
            {...register(GcpFormFields.BIG_QUERY_TABLE_NAME)}
            placeholder="e.g., table1_XXXXXX_XXXXXX_XXXXXX"
          />
        </FormGroup>
        <FormGroup error={errors[GcpFormFields.USE_DETAILED_BILLING_DATA]}>
          <Controller
            name={GcpFormFields.USE_DETAILED_BILLING_DATA}
            control={control}
            render={({ field: { onChange } }) => (
              <Switch
                id={GcpFormFields.USE_DETAILED_BILLING_DATA}
                labelText="Use Advanced Billing Data"
                onChange={onChange}
              />
            )}
          />
        </FormGroup>
        <FlexContainer gap={theme.spacing.spacing03}>
          <Button type="submit" icon="checkmark">
            Apply
          </Button>
          <Button type="button" severity="low" onClick={onCloseForm}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}

function FormHints() {
  const setupNotes = useMemo(
    () => [
      {
        title: "BigQuery Data Viewer",
      },
      {
        title: "BigQuery User",
      },
    ],
    []
  );

  return (
    <>
      <FormHint stepNumber={1}>
        <Text>
          Before exporting your Cloud Billing Data, you must set up a project to
          contain your BigQuery dataset.
        </Text>
      </FormHint>
      <FormHint stepNumber={2}>
        <Text>
          To export your Cloud Billing Data, follow these instructions:
          <br />
          <ExternalLink href="https://cloud.google.com/billing/docs/how-to/export-data-bigquery">
            https://cloud.google.com/billing/docs/how-to/export-data-bigquery
          </ExternalLink>
        </Text>
      </FormHint>
      <FormHint stepNumber={3}>
        <section>
          <Text>
            Create a Service Account and set the following roles in that Service
            Account project:
          </Text>
          <List
            unordered
            ml={`-${theme.spacing.spacing02}`}
            p={0}
            items={setupNotes}
          />
        </section>
      </FormHint>
      <FormHint stepNumber={4}>
        <Text>
          Complete the information below to access your billing information.
        </Text>
      </FormHint>
    </>
  );
}

const UPLOAD_PLACEHOLDER_TEXT = "Upload service account key as .json file";
const ERROR_READ_FILE = "Cannot read file contents";

function readFile<T>(file: File): Promise<T> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // eslint-disable-next-line unicorn/prefer-add-event-listener
    reader.onload = function (event) {
      try {
        const result = event.target?.result;
        if (typeof result === "string") {
          const parsed = JSON.parse(result) as T;
          resolve(parsed);
        }
      } catch {
        reject(new Error("Failed parsing file."));
      }
    };

    reader.readAsText(file);
  });
}
