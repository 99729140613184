/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  Provider,
  useUpdateAwsAccountCredentialsMutation,
  useUpdateAzureAccountCredentialsMutation,
  useUpdateGcpAccountCredentialsMutation,
  useUpdateIbmAccountCredentialsMutation,
  UpdateGcpAccountCredentialsInput,
} from "~/generated/graphql";
import { getProvider, getProviderType } from "../utils";
import { Account, Providers, UpdateHandlerVariables } from "./types";

export function useUpdateAccountCredentials({ account }: { account: Account }) {
  const provider = getProvider(account) as Providers;
  const providerType = getProviderType(account) || undefined;
  const accountId = account?.id ?? "";

  const [updateAwsCredentials] = useUpdateAwsAccountCredentialsMutation();
  const [updateGcpCredentials] = useUpdateGcpAccountCredentialsMutation();
  const [updateIbmCredentials] = useUpdateIbmAccountCredentialsMutation();
  const [updateAzureCredentials] = useUpdateAzureAccountCredentialsMutation();

  async function handleUpdate<T extends Providers>(
    variables: UpdateHandlerVariables<T>
  ) {
    if (provider === Provider.Aws) {
      const { arn, externalId } =
        variables as UpdateHandlerVariables<Provider.Aws>;

      return await updateAwsCredentials({
        variables: { id: accountId, roles: [{ arn, externalId }] },
      });
    }
    if (provider === Provider.Gcp) {
      const { key } = variables as UpdateHandlerVariables<Provider.Gcp>;

      return await updateGcpCredentials({
        variables: {
          id: accountId,
          input: JSON.parse(key) as UpdateGcpAccountCredentialsInput,
        },
      });
    }
    if (provider === Provider.Ibmcloud) {
      const { apikey } = variables as UpdateHandlerVariables<Provider.Ibmcloud>;

      return await updateIbmCredentials({
        variables: {
          id: accountId,
          apikey,
        },
      });
    }
    if (provider === Provider.Azure) {
      const { tenantId, appId, password } =
        variables as UpdateHandlerVariables<Provider.Azure>;

      return await updateAzureCredentials({
        variables: {
          id: accountId,
          tenantId,
          appId,
          password,
        },
      });
    }
  }

  return { handleUpdate, provider, providerType };
}
