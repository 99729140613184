/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, Text, theme } from "@nordcloud/gnui";
import {
  BudgetsConfig,
  CostsConfig,
  KPIConfig,
  SavingsSuggestionsConfig,
  WorkflowDataType,
  AnomalyCostsConfig,
} from "~/views/workflows/constants";

const UnallocatedResourcesTooltipContent = (
  <FlexContainer direction="column">
    <Text size="sm" color={theme.color.text.text04}>
      When a workflow is run for the first time, the number of unallocated
      resources represents all unallocated resources at the time the workflow is
      run.
    </Text>
    <Text size="sm" color={theme.color.text.text04} m="0">
      If a workflow has been run at least once, the number of unallocated
      resources represents all unallocated resources discovered since the last
      run of the workflow.
    </Text>
  </FlexContainer>
);

export const tooltipMap: Record<WorkflowDataType, JSX.Element | string> = {
  SAVING_SUGGESTIONS: SavingsSuggestionsConfig.description,
  COSTS: CostsConfig.description,
  BUDGETS: BudgetsConfig.description,
  UNALLOCATED_RESOURCES: UnallocatedResourcesTooltipContent,
  KPI: KPIConfig.description,
  ANOMALY_COSTS: AnomalyCostsConfig.description,
};
