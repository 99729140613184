/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormContext, UseFormGetValues } from "react-hook-form";
import { theme } from "@nordcloud/gnui";
import { useBusinessContextsQuery, Maybe } from "~/generated/graphql";
import { showSimpleInfo } from "~/services/toast";
import { inflect, joinSentenceSubjects, PathName } from "~/tools";
import {
  BudgetsConfig,
  ComponentConfigMap,
  ComponentType,
  CostsConfig,
  WorkflowDataType,
  AnomalyCostsConfig,
} from "~/views/workflows/constants";
import { getDefaultValues, WorkflowFormData } from "~/views/workflows/types";
import { tooltipMap } from "./tooltips";
import { isSelectDataType, setServiceNowShortDescription } from "./utils";

type Props = {
  type?: WorkflowDataType;
};

type UseHold = TypeRelatedProperties & {
  handleDrop: (componentType: ComponentType) => void;
  handleRemove: () => void;
};

const grey = theme.color.text.text02;

export const useHoldSelectDataComponent = ({ type }: Props): UseHold => {
  const { setValue, trigger, resetField, watch, getValues } =
    useFormContext<WorkflowFormData>();

  const selectedBusinessContext = useBusinessContext(getValues);

  const handleDrop = (componentType: ComponentType) => {
    if (!isSelectDataType(componentType)) {
      return;
    }

    const [sendDataComponent] = watch("sendDataComponents");
    if (sendDataComponent !== undefined) {
      resetField("email", { defaultValue: getDefaultValues().email });
      resetField("emailSubmissionSnapshot", { defaultValue: null });
      resetField("emailSaved", { defaultValue: false });

      const title = ComponentConfigMap[sendDataComponent].title;
      showSimpleInfo(
        `Please review the ${title} send data component configuration.`,
        5000
      );
    }

    handleRemove();
    setValue("selectDataComponent", componentType, { shouldDirty: true });
    trigger("selectDataComponent");

    if (
      componentType === WorkflowDataType.KPI &&
      selectedBusinessContext !== undefined &&
      selectedBusinessContext?.isPrimary
    ) {
      setValue(
        "kpiOptions",
        { businessContextId: selectedBusinessContext.id },
        { shouldDirty: true }
      );
    }

    setValue("slack", {
      ...getValues("slack"),
      message: undefined,
    });
    setValue("serviceNow", {
      ...getValues("serviceNow"),
      description: undefined,
    });

    setServiceNowShortDescription(setValue, componentType);
  };

  const handleRemove = () => {
    setValue("selectDataComponent", undefined, { shouldDirty: true });

    switch (type) {
      case "SAVING_SUGGESTIONS":
        resetField("savingsSuggestions", {
          defaultValue: getDefaultValues().savingsSuggestions,
        });

        break;
      case "KPI":
        resetField("kpiOptions", {
          defaultValue: getDefaultValues().kpiOptions,
        });

        break;
      case "COSTS":
        resetField("costsOptions", {
          defaultValue: getDefaultValues().costsOptions,
        });

        break;
      case "BUDGETS":
        resetField("budgetsOptions", {
          defaultValue: getDefaultValues().budgetsOptions,
        });
        resetField("budgetsSubmissionSnapshot", {
          defaultValue: undefined,
        });

        break;
      case "ANOMALY_COSTS":
        resetField("anomalyCostsOptions", {
          defaultValue: getDefaultValues().anomalyCostsOptions,
        });
        resetField("anomalyCostsOptionsSubmissionSnapshot", {
          defaultValue: undefined,
        });

        break;
      default:
        console.warn("Unknown selectData component type:", type);

        break;
    }
  };

  const typeRelatedProperties = getTypeRelatedProperties({
    type,
    getValues,
    selectedBusinessContext,
  });

  return { handleDrop, handleRemove, ...typeRelatedProperties };
};

type BusinessContext = {
  id: string;
  name: string;
  color?: Maybe<string>;
  isPrimary?: Maybe<boolean>;
};

type TypeRelatedProperties = {
  iconName: PathName;
  tagText: string;
  tagTextColor: string;
  tooltipContent: JSX.Element | string;
  title: string;
};

type GetTypeRelatedPropertiesProps = {
  type?: WorkflowDataType;
  getValues: UseFormGetValues<WorkflowFormData>;
  selectedBusinessContext?: BusinessContext;
};

function getTypeRelatedProperties({
  type,
  getValues,
  selectedBusinessContext,
}: GetTypeRelatedPropertiesProps): TypeRelatedProperties {
  switch (type) {
    case WorkflowDataType.KPI:
      return {
        ...getKpiTag(selectedBusinessContext),
        tooltipContent: tooltipMap.KPI,
        title: "KPIs",
        iconName: "chartAscending",
      };
    case WorkflowDataType.UNALLOCATED_RESOURCES:
      return {
        tagText: "Select all new unallocated resources",
        tagTextColor: grey,
        tooltipContent: tooltipMap.UNALLOCATED_RESOURCES,
        title: "New Unallocated Resources",
        iconName: "resource",
      };
    case WorkflowDataType.SAVING_SUGGESTIONS: {
      const [apps = [], envs = [], orgs = []] = getValues([
        "savingsSuggestions.applications",
        "savingsSuggestions.environments",
        "savingsSuggestions.orgUnitIds",
      ]);

      return {
        ...getAppsEnvsOrgsTagText(apps, envs, orgs),
        tooltipContent: tooltipMap.SAVING_SUGGESTIONS,
        title: "Savings Suggestions",
        iconName: "cloudWaste",
      };
    }
    case WorkflowDataType.COSTS: {
      const [apps = [], envs = [], orgs = []] = getValues([
        "costsOptions.applications",
        "costsOptions.environments",
        "costsOptions.orgUnitIds",
      ]);

      return {
        ...CostsConfig,
        ...getAppsEnvsOrgsTagText(apps, envs, orgs),
        tooltipContent: tooltipMap.COSTS,
      };
    }
    case WorkflowDataType.BUDGETS: {
      const [apps = [], envs = [], orgs = []] = getValues([
        "budgetsOptions.applications",
        "budgetsOptions.environments",
        "budgetsOptions.orgUnitIds",
      ]);

      return {
        ...BudgetsConfig,
        ...getAppsEnvsOrgsTagText(apps, envs, orgs),
        tooltipContent: tooltipMap.BUDGETS,
      };
    }
    case WorkflowDataType.ANOMALY_COSTS: {
      const [apps = []] = getValues(["anomalyCostsOptions.applications"]);

      return {
        ...AnomalyCostsConfig,
        ...getAppsText(apps),
        tooltipContent: tooltipMap.COSTS,
      };
    }
    default:
      return {
        tagText: "",
        tagTextColor: grey,
        tooltipContent: "",
        title: "",
        iconName: "resource",
      };
  }
}

function getAppsText(apps: string[]) {
  if (apps.length === 0) {
    return {
      tagText: "Please configure this component",
      tagTextColor: "danger",
    };
  }

  const subject = joinSentenceSubjects([
    { subject: "Application", count: apps.length },
  ]);

  const auxiliaryVerb = inflect("is", "are")(apps.length);

  return {
    tagText: `${subject} ${auxiliaryVerb} selected`,
    tagTextColor: grey,
  };
}

function getAppsEnvsOrgsTagText(
  apps: string[],
  envs: string[],
  orgs: string[]
) {
  const totalLength = apps.length + envs.length + orgs.length;
  if (totalLength === 0) {
    return {
      tagText: "Please configure this component",
      tagTextColor: "danger",
    };
  }

  const subject = joinSentenceSubjects([
    { subject: "Organizational Unit", count: orgs.length },
    { subject: "Application", count: apps.length },
    { subject: "Environment", count: envs.length },
  ]);

  const auxiliaryVerb = inflect("is", "are")(totalLength);

  return {
    tagText: `${subject} ${auxiliaryVerb} selected`,
    tagTextColor: grey,
  };
}

function useBusinessContext(getValues: UseFormGetValues<WorkflowFormData>) {
  const { data } = useBusinessContextsQuery({
    variables: { limit: 100, page: 0 },
  });

  const bcs = data?.businessContexts?.businessContexts ?? [];
  const selectedBCId = getValues(["kpiOptions"])[0]?.businessContextId;

  return bcs.find((bc) => bc.id === selectedBCId);
}

function getKpiTag(selectedBusinessContext?: BusinessContext) {
  if (selectedBusinessContext !== undefined) {
    return {
      tagText: `"${selectedBusinessContext.name}" Business Context is selected`,
      tagTextColor: grey,
    };
  }

  return {
    tagText: "Please configure this component",
    tagTextColor: "danger",
  };
}
