/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Button } from "@nordcloud/gnui";
import { CloudAccount } from "~/views/cloudAccounts/types";
import { downloadDeployment, isVMWare } from "~/views/cloudAccounts/utils";
import { Account } from "../types";

type Props = {
  account: Account;
  size?: "md" | "sm";
  severity?: "high" | "low" | "medium";
  status?: "discovery";
};

export function HybridCloudDownloadButton({
  account,
  size,
  severity,
  status,
}: Props) {
  const isVMWareAccount = isVMWare(account.providerType);
  const buttonText = isVMWareAccount
    ? "Download Config File"
    : "Download Deployment YAML";

  return (
    <Button
      size={size}
      severity={severity}
      status={status}
      icon="download"
      onClick={() => downloadDeployment(account as CloudAccount)}
    >
      {buttonText}
    </Button>
  );
}
