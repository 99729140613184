/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Sidebar, theme, Spacer } from "@nordcloud/gnui";

import { AccountV2Query } from "~/generated/graphql";
import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { showSuccess, showError } from "~/services/toast";
import { FormSwitch } from "./FormSwitch";
import { Providers, UpdateHandlerVariables } from "./types";
import { useUpdateAccountCredentials } from "./useUpdateAccountCredentials";

type Props = {
  isOpen: boolean;
  close: () => void;
  account?: AccountV2Query["accountV2"];
};

export function AccountCredentialsUpdateSidebar({
  isOpen,
  close,
  account,
}: Props) {
  const { handleUpdate, provider, providerType } = useUpdateAccountCredentials({
    account,
  });

  const handleSubmit = async (variables: UpdateHandlerVariables<Providers>) => {
    try {
      await handleUpdate(variables);
      showSuccess(SUCCESS_TEXT.accountCredentialsUpdated);
      close();
    } catch {
      showError(ERROR_TEXT.failedUpdateAccountCredentials);
    }
  };

  return (
    <Sidebar
      width="47rem"
      isOpen={isOpen}
      title="Update Credentials"
      onClick={close}
    >
      <FormSwitch
        handleSubmit={(variables) => handleSubmit(variables)}
        close={close}
        account={account}
        provider={provider}
        providerType={providerType}
      />
      <Spacer height={theme.spacing.spacing04} />
    </Sidebar>
  );
}
