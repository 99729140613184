/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  UnpackNestedValue,
  useFormContext,
  UseFormWatch,
} from "react-hook-form";
import { theme } from "@nordcloud/gnui";
import { WorkflowTriggerType } from "~/generated/graphql";
import { isEmpty, isSomeEnum, PathName } from "~/tools";
import {
  ComponentType,
  ScanConfig,
  SchedulerConfig,
} from "~/views/workflows/constants";
import { getDefaultValues, WorkflowFormData } from "~/views/workflows/types";
import { getScheduleSummaryTextFromFormData } from "~/views/workflows/utils";

type Props = {
  type?: WorkflowTriggerType;
  initialValues?: TriggerInitialValues;
};

type UseHold = TextProperties & {
  handleDrop: (componentType: ComponentType) => void;
  handleRemove: () => void;
};

export const useHoldTrigger = ({ type, initialValues }: Props): UseHold => {
  const { setValue, resetField, trigger, watch } =
    useFormContext<WorkflowFormData>();

  const handleRemove = () => {
    const defaultValues = getDefaultValues();

    setValue("triggerComponents", [], { shouldDirty: true });

    if (type === "SCHEDULER") {
      resetField("scheduler", {
        defaultValue: initialValues ?? defaultValues.scheduler,
      });
      resetField("schedulerSubmissionId", {
        defaultValue: defaultValues.schedulerSubmissionId,
      });
      resetField("schedulerSubmissionSnapshot", {
        defaultValue: undefined,
      });
    }
  };

  const handleDrop = (componentType: ComponentType) => {
    if (!isTriggerType(componentType)) {
      return;
    }

    handleRemove();
    setValue("triggerComponents", [componentType], { shouldDirty: true });
    trigger("triggerComponents");
  };

  const textProperties = getTextProperties({ watch, initialValues, type });

  return {
    handleDrop,
    handleRemove,
    ...textProperties,
  };
};

type TriggerInitialValues = UnpackNestedValue<WorkflowFormData>["scheduler"];

type GetTextPropertiesProps = {
  watch: UseFormWatch<WorkflowFormData>;
  initialValues?: TriggerInitialValues;
  type?: WorkflowTriggerType;
};

function getTextProperties({
  type,
  initialValues,
  watch,
}: GetTextPropertiesProps): TextProperties {
  const UNCONFIGURED_COMPONENT_TEXT = "Please configure this component";

  switch (type) {
    case "SCHEDULER": {
      const schedulerSubmissionId = watch("schedulerSubmissionId");
      if (initialValues === undefined && isEmpty(schedulerSubmissionId)) {
        return {
          tagText: UNCONFIGURED_COMPONENT_TEXT,
          tagTextColor: "danger",
          description: SchedulerConfig.description,
          title: "Scheduler",
          iconName: "eventSchedule",
        };
      }
      const scheduler = watch("scheduler");

      return {
        tagText: getScheduleSummaryTextFromFormData(scheduler),
        tagTextColor: theme.color.text.text02,
        description: SchedulerConfig.description,
        title: "Scheduler",
        iconName: "eventSchedule",
      };
    }
    case "SCAN":
      return {
        tagText: "When daily scanning of accounts is completed",
        tagTextColor: theme.color.text.text02,
        description: ScanConfig.description,
        title: "Scan Completed",
        iconName: "zoomArea",
      };
    default:
      return {
        tagText: UNCONFIGURED_COMPONENT_TEXT,
        tagTextColor: "danger",
        description: "",
        title: "",
        iconName: "default",
      };
  }
}

const isTriggerType = isSomeEnum(WorkflowTriggerType);

type TextProperties = {
  iconName: PathName;
  tagText: string;
  tagTextColor: string;
  description: string;
  title: string;
};
