/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import type { CSSObject } from "styled-components";
import { Loader } from "@nordcloud/gnui";

export function InlineSpinner({
  show,
  css,
  ...rest
}: React.ComponentPropsWithoutRef<"span"> & { show: boolean; css: CSSObject }) {
  if (show) {
    return (
      <span
        css={Object.assign(
          {
            display: "inline-block",
          },
          css
        )}
        {...rest}
      >
        <Loader inContent />
      </span>
    );
  }

  return null;
}
