/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  GcpBillingDataDocument,
  GcpBillingDataQuery,
  GcpBillingDataQueryVariables,
  useAddGcpBillingDataMutation,
} from "~/generated/graphql";
import { ERROR_TEXT } from "~/constants";
import { FormData } from "~/views/cloudAccounts/BillingData/gcp/types";

export function useAddGcpBillingData() {
  const [addGcpCapacity, { client, ...mutationState }] =
    useAddGcpBillingDataMutation();

  const addGcpBillingData = async (data: FormData) => {
    const response = await addGcpCapacity({ variables: { input: data } });

    if (response.data?.addGCPBillingConfig?.success) {
      client.cache.updateQuery<
        GcpBillingDataQuery,
        GcpBillingDataQueryVariables
      >(
        {
          query: GcpBillingDataDocument,
        },
        (billingData) => ({
          gcpBillingData: [
            ...(billingData?.gcpBillingData ?? []),
            {
              onboardedOrgId: "",
              isActive: false,
              projectId: data.projectId,
              organizationId: data.organizationId,
              isOnboarding: false,
            },
          ],
        })
      );

      return;
    }

    throw new Error(ERROR_TEXT.default);
  };

  return [addGcpBillingData, mutationState] as const;
}
