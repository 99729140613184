/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useRef, useState } from "react";
import { When } from "react-if";
import styled from "styled-components";
import { FlexContainer, Loader } from "@nordcloud/gnui";
import { FilterHeader, Mode } from "~/components";
import { useAppEnvFilterSelector, useInfiniteApplications } from "../hooks";
import { FiltersProp } from "../types";
import { SelectableApplicationRow } from "./SelectableApplicationRow";

type Props = {
  selectedApplications: string[];
  selectedEnvironments: string[];
  isCleared: boolean;
  onMultiSelect: (filter: FiltersProp) => void;
  headerProps?: {
    allowModeChange?: boolean;
    defaultMode?: Mode;
    onModeChange?: (mode: Mode) => void;
  };
};

const defaultHeaderProps = {};

export function AppEnvAccordion({
  selectedApplications,
  selectedEnvironments,
  isCleared,
  onMultiSelect,
  headerProps = defaultHeaderProps,
}: Props) {
  const [applicationName, setApplicationName] = useState("");
  const elementRef = useRef(null);

  const {
    isAppsLoading,
    applications,
    selectedApps,
    selectedEnvs,
    setSelectedApps,
    setSelectedEnvs,
  } = useInfiniteApplications({
    elementRef,
    selectedApplications,
    selectedEnvironments,
    applicationName,
    isCleared,
  });

  const { selectApp, selectEnv } = useAppEnvFilterSelector({
    apps: applications,
    selectedApps,
    setSelectedApps,
    selectedEnvs,
    setSelectedEnvs,
  });

  useEffect(() => {
    onMultiSelect({
      application: selectedApps.map(({ nid }) => nid),
      environment: selectedEnvs.map(({ nid }) => nid),
    });
  }, [selectedApps, selectedEnvs]);

  return (
    <>
      <FilterHeader
        showSearchBar
        onSetSearch={setApplicationName}
        {...headerProps}
      />
      <SelectableApplicationRowWrapper>
        {applications.map((application) => (
          <SelectableApplicationRow
            key={application.nid}
            application={application}
            selectedApps={selectedApps}
            selectedEnvs={selectedEnvs}
            disabled={false}
            onAppCheck={selectApp}
            onEnvCheck={selectEnv}
          />
        ))}
        <When condition={isAppsLoading}>
          <Loader inContent />
        </When>
        <div ref={elementRef} />
      </SelectableApplicationRowWrapper>
    </>
  );
}

const SelectableApplicationRowWrapper = styled(FlexContainer)`
  align-items: flex-start;
  flex-direction: column;
  max-height: 20rem;
  overflow-y: scroll;
  overflow-x: hidden;
`;
