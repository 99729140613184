/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { MutableRefObject, useEffect, useState } from "react";
import { useApplicationsPaginatedByApplicationNameLazyQuery } from "~/generated/graphql";
import { getFirstItem } from "~/tools";
import { formatDataObject } from "~/utils";
import {
  generateSelectedApps,
  generateSelectedEnvs,
} from "../AppEnvSelectorPaginated/utils";
import { ApplicationOption, EnvironmentOption } from "../types";

type Props = {
  elementRef: MutableRefObject<null>;
  selectedApplications: string[];
  selectedEnvironments: string[];
  applicationName: string;
  isCleared: boolean;
};

export function useInfiniteApplications({
  elementRef,
  selectedApplications,
  selectedEnvironments,
  applicationName,
  isCleared,
}: Props) {
  const pageSize = 20;
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [applications, setApplications] = useState<ApplicationOption[]>([]);
  const [selectedApps, setSelectedApps] = useState<ApplicationOption[]>(
    generateSelectedApps(selectedApplications)
  );
  const [selectedEnvs, setSelectedEnvs] = useState<EnvironmentOption[]>(
    generateSelectedEnvs(selectedEnvironments)
  );

  const [getApplications, { data, loading: isAppsLoading }] =
    useApplicationsPaginatedByApplicationNameLazyQuery({
      variables: {
        limit: pageSize,
        page,
        applicationName,
      },
    });

  useEffect(() => {
    if (isCleared) {
      setSelectedApps(generateSelectedApps(selectedApplications));
      setSelectedEnvs(generateSelectedEnvs(selectedEnvironments));
    }
  }, [isCleared]);

  const fetchData = async () => {
    await getApplications();
    setPage((prevPage) => prevPage + 1);
    setHasMore(
      Number(data?.applicationsPaginated?.count) > applications.length
    );
  };

  useEffect(() => {
    setPage(0);
    setApplications([]);
  }, [applicationName]);

  function onIntersection(entries: IntersectionObserverEntry[]) {
    const firstEntry = getFirstItem(entries);
    if (firstEntry.isIntersecting && hasMore) {
      void fetchData();
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection);
    if (observer && elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [elementRef]);

  useEffect(() => {
    const applicationsPaginated = formatDataObject(data);
    setApplications((prevApplications) => {
      const newApplications =
        applicationsPaginated?.applicationsPaginated?.results ?? [];

      return [...prevApplications, ...newApplications].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    });
  }, [data]);

  return {
    isAppsLoading,
    applications,
    selectedApps,
    selectedEnvs,
    setSelectedApps,
    setSelectedEnvs,
  };
}
