/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Case, Switch } from "react-if";
import { AccountV2Query } from "~/generated/graphql";
import { CloudProviderType, Provider } from "~/tests/types";
import { AwsUpdateCredentialsForm } from "./AwsUpdateCredentialsForm";
import { AzureUpdateCredentialsForm } from "./AzureUpdateCredentialsForm";
import { GcpUpdateCredentialsForm } from "./GcpUpdateCredentialsForm";
import { IbmUpdateCredentialsForm } from "./IbmUpdateCredentialsForm";
import { KubernetesOpenShiftUpdateCredentialsForm } from "./KubernetesOpenShiftUpdateCredentialsForm";
import { Providers, UpdateHandlerVariables } from "./types";
import { VmWareUpdateCredentialsForm } from "./WareUpdateCredentialsForm";

type Props = {
  close: () => void;
  provider: Provider;
  providerType?: CloudProviderType;
  account: AccountV2Query["accountV2"];
  handleSubmit: (variables: UpdateHandlerVariables<Providers>) => void;
};

export function FormSwitch({
  close,
  provider,
  providerType,
  account,
  handleSubmit,
}: Props) {
  return (
    <Switch>
      <Case condition={provider === Provider.Aws}>
        <AwsUpdateCredentialsForm onSubmit={handleSubmit} onClose={close} />
      </Case>
      <Case condition={provider === Provider.Azure}>
        <AzureUpdateCredentialsForm onSubmit={handleSubmit} onClose={close} />
      </Case>
      <Case condition={provider === Provider.Gcp}>
        <GcpUpdateCredentialsForm onSubmit={handleSubmit} onClose={close} />
      </Case>
      <Case condition={provider === Provider.Ibmcloud}>
        <IbmUpdateCredentialsForm onSubmit={handleSubmit} onClose={close} />
      </Case>
      <Case
        condition={
          provider === Provider.Hybrid &&
          providerType &&
          [CloudProviderType.Kubernetes, CloudProviderType.Openshift].includes(
            providerType
          )
        }
      >
        <KubernetesOpenShiftUpdateCredentialsForm />
      </Case>
      <Case
        condition={
          provider === Provider.Hybrid &&
          providerType &&
          CloudProviderType.Vmware === providerType
        }
      >
        <VmWareUpdateCredentialsForm account={account} />
      </Case>
    </Switch>
  );
}
