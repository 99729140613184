/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useMemo, useRef } from "react";
import dayjs from "dayjs";
import { When } from "react-if";
import { useClickAway, useToggle } from "react-use";
import styled from "styled-components";
import {
  Box,
  Button,
  FlexContainer,
  Input,
  Label,
  SelectButton,
  Text,
  theme,
} from "@nordcloud/gnui";
import { dateFormat } from "~/constants";
import { useDateValues } from "~/hooks";
import { useBillingPeriod } from "~/services/customers";
import { DateRange, Period } from "../Charts/CostAnalysis/types";
import { Separator } from "../Styled";
import { DatePreselects } from "./DatePreselects";
import { ButtonWrapper, Content, Wrapper } from "./styles";
import { EstatePeriod } from "./types";

type Props = {
  isActive: boolean;
  onApply: (range?: DateRange) => void;
  label: string;
  period: EstatePeriod | Period;
  onClear: () => void;
  range?: DateRange;
  disabled?: boolean;
  showPreselects?: boolean;
};

export function DateRangeSelector({
  isActive,
  onApply,
  label,
  period,
  onClear,
  range,
  disabled = false,
  showPreselects = false,
}: Props) {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const wrapper = useRef<HTMLDivElement>(null);
  const { isCurrentBillingPeriod } = useBillingPeriod();
  const {
    endDate,
    setEndDate,
    startDate,
    setStartDate,
    previousPeriodSelected,
    setPreviousPeriodSelected,
    handlePrevPeriodClick,
  } = useDateValues(range);

  const isActionDisabled = useMemo(
    () => isButtonDisabled(startDate, endDate, range),
    [startDate, endDate, range]
  );

  const defaultDate = isCurrentBillingPeriod
    ? dayjs()
    : dayjs().subtract(1, "month").endOf("month");

  useClickAway(wrapper, () => toggleIsOpen(false));

  const handleRangeClick = useCallback(() => {
    if (period !== EstatePeriod.RANGE && period !== Period.RANGE && range) {
      onApply(range);
    } else {
      toggleIsOpen();
    }
  }, [range, period, toggleIsOpen, onApply]);

  const handleApply = useCallback(() => {
    onApply({
      from: startDate ?? "",
      to: endDate ?? "",
    });
    toggleIsOpen();
  }, [startDate, endDate, onApply, toggleIsOpen]);

  const handleClear = () => {
    onClear();
    setStartDate(undefined);
    setEndDate(undefined);
    setPreviousPeriodSelected(undefined);
  };

  const handleStartDateSelection = (value: string) => {
    setStartDate(value);
    if (dayjs(endDate).isBefore(value)) {
      setEndDate(undefined);
    }
  };

  const today = isCurrentBillingPeriod
    ? dayjs().format(dateFormat.shortDate)
    : dayjs().subtract(1, "month").endOf("month").format(dateFormat.shortDate);

  return (
    <Wrapper ref={wrapper}>
      <ButtonWrapper>
        <SelectButton
          name="range"
          value="13"
          labelText={label}
          isActive={isActive}
          style={{ whiteSpace: "nowrap", minWidth: "7.5rem" }}
          disabled={disabled}
          onClick={handleRangeClick}
        />
        <When condition={label !== "Date Range"}>
          <Button
            icon="close"
            size="sm"
            severity={isActive ? undefined : "medium"}
            css={{ borderRadius: "unset", border: "none" }}
            onClick={handleClear}
          />
        </When>
      </ButtonWrapper>
      <Content isVisible={isOpen}>
        <Box innerSpacing="spacing03" shadow="shadow04">
          <When condition={showPreselects}>
            <DatePreselects
              periodSelected={previousPeriodSelected}
              handleClick={handlePrevPeriodClick}
            />
          </When>
          <Text weight="bold">Date range</Text>
          <FlexContainer>
            <DateInputContainer>
              <DateLabel name="Start date:" />
            </DateInputContainer>
            <DateInputContainer>
              <DateLabel name="End date:" />
            </DateInputContainer>
          </FlexContainer>
          <FlexContainer>
            <DateInputContainer>
              <Input
                type="date"
                value={startDate ?? ""}
                placeholder="MM/DD/YYYY"
                max={today}
                min={getDateToMinValue(defaultDate)}
                onChange={(event) =>
                  handleStartDateSelection(event.target.value)
                }
              />
            </DateInputContainer>
            <DateInputContainer>
              <Input
                type="date"
                value={endDate ?? ""}
                placeholder="MM/DD/YYYY"
                max={today}
                disabled={startDate === undefined}
                min={getDateToMinValue(defaultDate, startDate)}
                onChange={(event) => setEndDate(event.target.value)}
              />
            </DateInputContainer>
          </FlexContainer>
          <Separator css={{ marginTop: theme.spacing.spacing04 }} />
          <Button
            disabled={isActionDisabled}
            size="md"
            css={{ width: "98%", display: "inline-block" }}
            marginTop={theme.spacing.spacing04}
            onClick={handleApply}
          >
            Apply
          </Button>
        </Box>
      </Content>
    </Wrapper>
  );
}

const DateInputContainer = styled.div`
  margin-right: ${theme.spacing.spacing02};
  width: 50%;
`;

const DateLabel = styled(Label)`
  font-weight: ${theme.fontWeights.medium};
  font-size: ${theme.fontSizes.sm};
`;

function isRangeSet(from?: string, to?: string) {
  return from !== undefined && to !== undefined;
}

function isButtonDisabled(from?: string, to?: string, range?: DateRange) {
  if (range) {
    return range.from === from && range?.to === to;
  }

  return !isRangeSet(from, to);
}

function getDateToMinValue(defaultDate: dayjs.Dayjs, startDate?: string) {
  return startDate
    ? dayjs(startDate).format(dateFormat.shortDate)
    : defaultDate.add(-5, "year").format(dateFormat.shortDate);
}
