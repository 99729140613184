/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReactNode } from "react";
import { When } from "react-if";
import { theme } from "@nordcloud/gnui";

type Props = {
  children: ReactNode;
  top?: string;
  left?: string;
  show?: boolean;
  color?: string;
  size?: string;
  outlineColor?: string;
};

export function AlertDotWrap({
  children,
  top = "-43px",
  left = "-28px",
  color = theme.colors.danger,
  size = "4",
  outlineColor = theme.color.background.ui01,
  show = false,
}: Props) {
  return (
    <div css={{ position: "relative" }}>
      <When condition={show}>
        <svg
          width="100"
          height="100"
          css={{
            position: "absolute",
            top,
            left,
          }}
        >
          <circle
            cx="50"
            cy="50"
            r={size}
            fill={color}
            stroke={outlineColor}
            strokeWidth="1"
          />
        </svg>
      </When>
      {children}
    </div>
  );
}
