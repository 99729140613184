/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { WorkflowDataType } from "~/views/workflows/constants";
import { AnomalyCostsDescriptionSection } from "./AnomalyCostsDescriptionSection";
import { BudgetsOptionsSection } from "./BudgetsOptionsSection";
import { CostsOptionsSection } from "./CostsOptionsSection";
import { KpiOptionsSection } from "./KpiOptionsSection";
import { SavingSuggestionsOptionsSection } from "./SavingSuggestionsOptionsSection";
import { UnallocatedResourcesDescriptionSection } from "./UnallocatedResourcesDescriptionSection";

type Props = {
  selectDataComponent: WorkflowDataType | undefined;
  isReadMode: boolean;
};

export function SelectDataComponentSwitch({
  selectDataComponent,
  isReadMode,
}: Props) {
  switch (selectDataComponent) {
    case WorkflowDataType.ANOMALY_COSTS:
      return <AnomalyCostsDescriptionSection isReadMode={isReadMode} />;
    case WorkflowDataType.BUDGETS:
      return <BudgetsOptionsSection isReadMode={isReadMode} />;
    case WorkflowDataType.COSTS:
      return <CostsOptionsSection isReadMode={isReadMode} />;
    case WorkflowDataType.KPI:
      return <KpiOptionsSection isReadMode={isReadMode} />;
    case WorkflowDataType.UNALLOCATED_RESOURCES:
      return <UnallocatedResourcesDescriptionSection isReadMode={isReadMode} />;
    case WorkflowDataType.SAVING_SUGGESTIONS:
      return <SavingSuggestionsOptionsSection isReadMode={isReadMode} />;
    default:
      return null;
  }
}
