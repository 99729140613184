/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import dayjs from "dayjs";
import { CostAnalysisFields, Granularity } from "~/components";
import { isNotNil } from "~/tools";
import { ChartJobData, ChartTimePoint } from "../types";

type Props = {
  chartData: ChartJobData | undefined;
  granularity: Granularity;
};

export function useEstateRecordsCostPerApplication({
  chartData,
  granularity,
}: Props) {
  const granularData = useMemo(
    () =>
      granularity === Granularity.DAYS
        ? chartData?.chartJob?.data
        : chartData?.chartMonthlyJob?.data,
    [chartData, granularity]
  );

  const costPerApplicationChartData = useMemo(
    () => generateSavingPlanCommitments(granularData?.timePoints ?? []),
    [granularData]
  );

  const costPerApplicationTotal = Number(
    chartData?.chartJob?.data?.total ?? "0"
  );

  const applications = getApplications(
    chartData?.chartJob?.data?.timePoints ?? []
  );

  return {
    costPerApplicationChartData,
    costPerApplicationTotal,
    applications,
  };
}

function getApplications(timePoints: ChartTimePoint[]) {
  const categories = timePoints
    .flatMap((timePoint) =>
      timePoint?.groups?.flatMap((group) => group?.name ?? "")
    )
    .filter(isNotNil);

  return [...new Set(categories)];
}

function generateSavingPlanCommitments(timepoints: ChartTimePoint[]) {
  const chartTimePoints = timepoints.filter(isNotNil);

  return chartTimePoints.map((timepoint) => {
    if (dayjs(timepoint.date).isAfter(dayjs(), "day")) {
      return {
        ...timepoint,
        groups: [
          {
            id: "savings",
            value: timepoint.value,
            name: CostAnalysisFields.SAVINGS_PLANS,
          },
        ],
      };
    }
    return {
      ...timepoint,
    };
  });
}
