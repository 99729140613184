/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  AwsBillingDataDocument,
  AwsBillingDataQuery,
  AwsBillingDataQueryVariables,
  useRemoveAwsBillingConfigMutation,
} from "~/generated/graphql";

export function useDeleteAwsBillingData() {
  const [deleteBillingDataItem, { client, ...mutationState }] =
    useRemoveAwsBillingConfigMutation();

  const removeAwsBillingData = async (data: { accountId: string }) => {
    const { data: removeResponse } = await deleteBillingDataItem({
      variables: { accountId: data.accountId },
    });

    if (removeResponse?.removeAwsBillingConfig) {
      client.cache.updateQuery<
        AwsBillingDataQuery,
        AwsBillingDataQueryVariables
      >(
        {
          query: AwsBillingDataDocument,
        },
        (billingData) => ({
          awsBillingData: (billingData?.awsBillingData ?? []).filter(
            (item) => item?.accountId !== data.accountId
          ),
        })
      );
    }
  };

  return [removeAwsBillingData, mutationState] as const;
}
