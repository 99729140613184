/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  FlexContainer,
  theme,
  Text,
  Label,
  Input,
  Button,
} from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";
import { FormGroup } from "~/components";
import {
  AWS_INFO_ONBOARDING,
  URL_AWS_TEMPLATE,
} from "../../AddAccount/constants";
import { AwsFormField } from "./constants";
import { StepContainer } from "./StepContainer";
import { UpdateHandlerVariables } from "./types";
import { awsCredentialsSchema } from "./validators";

type Props = {
  onClose: () => void;
  accountId?: string;
  onSubmit: (variables: UpdateHandlerVariables<Provider.Aws>) => void;
};

export function AwsUpdateCredentialsForm({ onClose, onSubmit }: Props) {
  const formMethods = useForm<UpdateHandlerVariables<Provider.Aws>>({
    resolver: yupResolver(awsCredentialsSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form
        css={{ width: "100%" }}
        id="AWS-cred-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text color={theme.color.text.text02} size="sm">
          {AWS_INFO_ONBOARDING}
        </Text>
        <FlexContainer
          css={{ width: "100%" }}
          alignItems="flex-start"
          direction="column"
          gap={theme.spacing.spacing03}
        >
          <StepContainer
            stepNumber={1}
            title={
              <Text tag="div">
                Sign in to the{" "}
                <Link
                  target="_blank"
                  rel="noreferrer"
                  to={{
                    pathname:
                      "https://us-east-1.console.aws.amazon.com/console/home?region=us-east-1",
                  }}
                >
                  AWS Management Console
                </Link>
              </Text>
            }
          />
          <StepContainer
            stepNumber={2}
            title={
              <Text tag="div">
                In AWS Management Console go to{" "}
                <Link
                  target="_blank"
                  rel="noreferrer"
                  to={{
                    pathname:
                      "https://console.aws.amazon.com/cloudformation/home",
                  }}
                >
                  CloudFormation
                </Link>
              </Text>
            }
          />
          <StepContainer
            stepNumber={3}
            title={
              <Text tag="div">
                Create a CloudFormation Stack based on{" "}
                <Link
                  target="_blank"
                  rel="noreferrer"
                  to={{
                    pathname: URL_AWS_TEMPLATE,
                  }}
                >
                  CloudFormation Template
                </Link>
              </Text>
            }
          >
            <>
              <Text
                size="sm"
                pt={theme.spacing.spacing01}
                color={theme.color.text.text02}
              >
                AWS External ID should be a randomly generated long string of
                different characters. It will be working as a password between
                IBM&nbsp;Multicloud&nbsp;Accelerator and AWS Account.
              </Text>
              <FormGroup error={errors[AwsFormField.EXTERNAL_ID]}>
                <Label
                  required
                  name="Provide Role External ID"
                  htmlFor={AwsFormField.EXTERNAL_ID}
                />
                <Input
                  {...register(AwsFormField.EXTERNAL_ID)}
                  id={AwsFormField.EXTERNAL_ID}
                />
              </FormGroup>
              <Link
                target="_blank"
                rel="noreferrer"
                to={{
                  pathname:
                    "https://aws.amazon.com/blogs/security/how-to-use-external-id-when-granting-access-to-your-aws-resources/",
                }}
                css={{
                  fontSize: theme.fontSizes.sm,
                }}
              >
                More information about External IDs
              </Link>
            </>
          </StepContainer>
          <StepContainer
            stepNumber={4}
            title={
              <Text tag="div">
                In AWS Management Console go to IAM and find role by name
              </Text>
            }
          >
            <>
              <Text>(NordcloudKlarityScannerServiceRole)</Text>
              <FormGroup error={errors[AwsFormField.ARN]}>
                <Label
                  required
                  name="Provide Role ARN"
                  htmlFor={AwsFormField.ARN}
                />
                <Input
                  {...register(AwsFormField.ARN)}
                  id={AwsFormField.ARN}
                  width="100%"
                  placeholder="e.g. arn:aws:iam::123456789012:role/NordcloudMCReadOnlyServiceRole"
                />
              </FormGroup>
            </>
          </StepContainer>
        </FlexContainer>
        <FlexContainer gap={theme.spacing.spacing04}>
          <Button type="submit" icon="checkmark">
            Apply
          </Button>
          <Button severity="low" onClick={onClose}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}
