/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  AzureBillingDataDocument,
  AzureBillingDataQuery,
  AzureBillingDataQueryVariables,
  useUpdateAzureBillingConfigMutation,
} from "~/generated/graphql";
import { UpdateFormData } from "../validators";

export function useUpdateAzureBillingData() {
  const [updateBillingDataItem, { client, ...mutationState }] =
    useUpdateAzureBillingConfigMutation();

  const updateAzureBillingData = async (data: UpdateFormData) => {
    const response = await updateBillingDataItem({
      variables: {
        input: {
          capacitorCustomerId: data.capacitorCustomerId,
          enrollmentNumber: data.enrollmentNumber,
          enrollmentAccessKey: data.enrollmentAccessKey,
        },
      },
    });

    if (response.data?.updateAzureBillingConfig) {
      client.cache.updateQuery<
        AzureBillingDataQuery,
        AzureBillingDataQueryVariables
      >(
        {
          query: AzureBillingDataDocument,
        },
        () => ({
          azureBillingData: [{ ...data, isActive: false, isOnboarding: false }],
        })
      );
    }
  };

  return [updateAzureBillingData, mutationState] as const;
}
