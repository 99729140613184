/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as yup from "yup";
import { REQUIRED_FIELD_WARNING } from "../../constants";
import { GcpFormFields } from "./types";

export const GcpFormSchema = yup.object().shape({
  [GcpFormFields.ORGANIZATION_ID]: yup
    .string()
    .min(3, "Minimum 3 characters required")
    .required(REQUIRED_FIELD_WARNING),
  [GcpFormFields.PROJECT_ID]: yup
    .string()
    .min(3, "Minimum 3 characters required")
    .required(REQUIRED_FIELD_WARNING),
  [GcpFormFields.BIG_QUERY_DATASET_NAME]: yup
    .string()
    .min(3, "Minimum 3 characters required")
    .required(REQUIRED_FIELD_WARNING),
  [GcpFormFields.BIG_QUERY_TABLE_NAME]: yup
    .string()
    .required(REQUIRED_FIELD_WARNING)
    .matches(
      /(?:[\dA-Z]{6}_){2}[\dA-Z]{6}/g,
      "BigQuery Table Name must match this format: table1_XXXXXX_XXXXXX_XXXXXX"
    ),
});
