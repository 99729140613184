/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, theme, Text, SVGIcon, Button } from "@nordcloud/gnui";
import { AccountV2Query } from "~/generated/graphql";
import DownloadDescriptorButton from "../../DownloadDescriptorButton";
import { getDownloadButtonProps } from "../utils";
import { Ul } from "./List";
import { StepContainer } from "./StepContainer";

type Props = {
  account?: AccountV2Query["accountV2"];
};

export function VmWareUpdateCredentialsForm({ account }: Props) {
  return (
    <FlexContainer
      css={{ width: "100%" }}
      alignItems="flex-start"
      direction="column"
      gap={theme.spacing.spacing03}
    >
      <Text>To run the VMware Scanner, you must:</Text>
      <Ul>
        <li>Download and install the Scanner in your VMware Environment.</li>
        <li>Configure and deploy the Scanner.</li>
        <li>Schedule the Scanner to run at regular intervals.</li>
      </Ul>
      <StepContainer
        stepNumber={1}
        title={
          <Text>
            Sign in to the In vCenter, create a service user with read-only
            permissions for your VMware installation. Record the username and
            password of this service user because you must add this information
            to the Scanner&apos;s configuration file.
          </Text>
        }
      />
      <StepContainer
        stepNumber={2}
        title={
          <Text>
            To download the relevant binary package (either Windows, or Linux)
            to the virtual machine, click below:
          </Text>
        }
      >
        <FlexContainer gap={theme.spacing.spacing04}>
          <DownloadLink operatingSystem="linux" />
          <DownloadLink operatingSystem="windows" />
        </FlexContainer>
      </StepContainer>
      <StepContainer
        stepNumber={3}
        title={
          <Text>
            The downloaded binary package includes a configuration file
          </Text>
        }
      >
        <FlexContainer>
          <DownloadDescriptorButton
            {...getDownloadButtonProps(account, {
              buttonName: "Download Config File",
              severity: "low",
              icon: "download",
            })}
          />
        </FlexContainer>
      </StepContainer>
      <StepContainer
        stepNumber={4}
        title={
          <Text>
            In config.json, change the values of the keys, as follows:
          </Text>
        }
      >
        <Ul>
          <li>
            <b>vmware_api_url</b> - Domain name or IP address of a VMware
            installation (for example, vmware.local).
          </li>
          <li>
            <b>vmware_api_username</b> - Username of created service account.
          </li>
          <li>
            <b>vmware_api_password</b> - Password of created service account.
          </li>
        </Ul>
      </StepContainer>
      <StepContainer
        stepNumber={5}
        title={
          <Text>Copy config.json to the same location as the binary.</Text>
        }
      />
      <StepContainer
        stepNumber={6}
        title={
          <Text>
            Schedule the binary to run at a certain time during the night, for
            example, 1:00 UTC.
          </Text>
        }
      />
      <StepContainer
        stepNumber={7}
        title={
          <Text>
            After configuration, first run the binary as a test. Verify with
            Nordcloud that the Scanner is importing estate record data as
            expected.
          </Text>
        }
      />
    </FlexContainer>
  );
}

function DownloadLink({
  operatingSystem,
}: {
  operatingSystem: "linux" | "windows";
}) {
  const { buttonText, link } = {
    buttonText:
      operatingSystem === "linux"
        ? "Download for Linux"
        : "Download for Windows",
    link:
      operatingSystem === "linux"
        ? "https://github.com/nordcloud/klarity-scanner-vmware-cli/releases/latest/download/klarity-scanner-vmware-cli-linux-amd64.zip"
        : "https://github.com/nordcloud/klarity-scanner-vmware-cli/releases/latest/download/klarity-scanner-vmware-cli-windows-amd64.zip",
  };
  return (
    <a
      target="_blank"
      href={link}
      rel="noreferrer"
      css={{
        ":hover": {
          textDecoration: "none",
        },
      }}
    >
      <Button severity="low">
        <FlexContainer>
          <SVGIcon name="download" />
          <Text tag="span">{buttonText}</Text>
        </FlexContainer>
      </Button>
    </a>
  );
}
