/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { EstatePeriod } from "~/components";
import { mapEstatePeriodToBillingPeriod } from "../../helpers";
import { BillingPeriod } from "../../types";

export function getSelectedBillingPeriod(selectedPeriod: EstatePeriod) {
  if (selectedPeriod !== EstatePeriod.RANGE) {
    return mapEstatePeriodToBillingPeriod(selectedPeriod);
  }

  return undefined;
}

export function getBillingPeriod(
  isPreviousBillingPeriod: boolean,
  queryStatePeriod?: BillingPeriod
) {
  if (queryStatePeriod) {
    return mapEstatePeriod(queryStatePeriod === BillingPeriod.Previous);
  }

  return mapEstatePeriod(isPreviousBillingPeriod);
}

export function mapEstatePeriod(isPreviousBillingPeriod: boolean) {
  return isPreviousBillingPeriod
    ? EstatePeriod.PREVIOUS_MONTH
    : EstatePeriod.CURRENT_MONTH;
}
