/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import {
  FlexContainer,
  theme,
  Text,
  Label,
  Input,
  Button,
  Spacer,
} from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";
import { ExternalLink, FormGroup } from "~/components";
import { CopyBox } from "../../AddAccount/components/CopyBox";
import { AZURE_COMMAND } from "../../AddAccount/constants";
import { AzureFormField } from "./constants";
import { StepContainer } from "./StepContainer";
import { UpdateHandlerVariables } from "./types";
import { azureCredentialsSchema } from "./validators";

type Props = {
  onClose: () => void;
  onSubmit: (variables: UpdateHandlerVariables<Provider.Azure>) => void;
};

export function AzureUpdateCredentialsForm({ onClose, onSubmit }: Props) {
  const formMethods = useForm<UpdateHandlerVariables<Provider.Azure>>({
    resolver: yupResolver(azureCredentialsSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form
        css={{ width: "100%" }}
        id="AZURE-cred-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text color={theme.color.text.text02} size="sm">
          To on-board a subscription you need to create a Service Principal.
        </Text>
        <FlexContainer
          css={{ width: "100%" }}
          alignItems="flex-start"
          direction="column"
          gap={theme.spacing.spacing03}
        >
          <StepContainer
            stepNumber={1}
            title={
              <Text tag="div">
                Sign in to the{" "}
                <ExternalLink href="https://portal.azure.com/">
                  Microsoft Azure
                </ExternalLink>
              </Text>
            }
          />
          <StepContainer
            stepNumber={2}
            title={
              <Text tag="div">
                Run Azure Cloud Shell and execute the following command:
              </Text>
            }
          >
            <>
              <Spacer height={theme.spacing.spacing02} />
              <CopyBox>{AZURE_COMMAND}</CopyBox>
            </>
          </StepContainer>
          <StepContainer
            stepNumber={3}
            title={
              <Text tag="div">
                The output will include credentials that you should provide
                below
              </Text>
            }
          >
            <FlexContainer
              pt={theme.spacing.spacing04}
              pb={theme.spacing.spacing02}
              gap={theme.spacing.spacing02}
              direction="column"
              alignItems="flex-start"
            >
              <FormGroup error={errors[AzureFormField.APP_ID]}>
                <Label required name="App ID" htmlFor={AzureFormField.APP_ID} />
                <Input
                  {...register(AzureFormField.APP_ID)}
                  id={AzureFormField.APP_ID}
                  placeholder="e.g. b7cb6335-43b6-45ed-8bcc-5b3a6541ffb2"
                />
              </FormGroup>
              <FormGroup error={errors[AzureFormField.PASSWORD]}>
                <Label
                  required
                  name="Password"
                  htmlFor={AzureFormField.PASSWORD}
                />
                <Input
                  type="password"
                  {...register(AzureFormField.PASSWORD)}
                  id={AzureFormField.PASSWORD}
                />
              </FormGroup>
              <FormGroup error={errors[AzureFormField.TENANT_ID]}>
                <Label
                  required
                  name="Tenant"
                  htmlFor={AzureFormField.TENANT_ID}
                />
                <Input
                  {...register(AzureFormField.TENANT_ID)}
                  id={AzureFormField.TENANT_ID}
                  placeholder="e.g. 4rcb6335-43b6-45ed-8bcc-5b3a6541fdd0"
                />
              </FormGroup>
            </FlexContainer>
          </StepContainer>
          <StepContainer
            stepNumber={4}
            title={
              <Text>
                Add the created Service Principal to your subscription with{" "}
                <b>Reader</b> role.
              </Text>
            }
          />
        </FlexContainer>
        <FlexContainer gap={theme.spacing.spacing04}>
          <Button type="submit" icon="checkmark">
            Apply
          </Button>
          <Button severity="low" onClick={onClose}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}
