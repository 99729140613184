/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import {
  Heading,
  Input,
  Sidebar,
  Spacer,
  theme,
  FlexContainer,
} from "@nordcloud/gnui";
import { useBusinessContextsQuery } from "~/generated/graphql";
import { WorkflowFormData } from "../types";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function KpiSettingsForm({ isOpen, onClose }: Props) {
  const { setValue, watch } = useFormContext<WorkflowFormData>();
  const { data } = useBusinessContextsQuery({
    variables: { limit: 100, page: 0 },
  });
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [filter, setFilter] = React.useState("");

  React.useEffect(() => {
    !isOpen && setFilter("");

    const inputRefCurrent = inputRef?.current;
    if (isOpen && inputRefCurrent) {
      inputRefCurrent.focus();
    }
  }, [setFilter, isOpen]);

  const handleBcChange = (id: string) => {
    setValue("kpiOptions.businessContextId", id, { shouldDirty: true });
    onClose();
  };

  const bcs = data?.businessContexts?.businessContexts ?? [];
  const selectedBCId = watch("kpiOptions")?.businessContextId;

  return (
    <Sidebar title="Select Business Context" isOpen={isOpen} onClick={onClose}>
      <Input
        ref={inputRef}
        value={filter}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFilter(e.target.value)
        }
        name="search"
        small
        placeholder="Search"
      />
      <Spacer height={theme.spacing.spacing02} />
      {filterByName(bcs, filter).map(({ id, name, color }) => (
        <Row
          key={id}
          isSelected={id === selectedBCId}
          onClick={() => handleBcChange(id)}
        >
          <FlexContainer p={theme.spacing.spacing02}>
            <Indicator color={color ?? theme.color.support.purple} />
            <Heading level={4}>{name}</Heading>
          </FlexContainer>
        </Row>
      ))}
    </Sidebar>
  );
}

const Indicator = styled.div<{ color: string }>`
  width: 0.75rem;
  height: 3rem;
  background-color: ${(props) => props.color};
  border-radius: 2px;
  margin-right: ${theme.spacing.spacing02};
`;

const Row = styled.div<{ isSelected?: boolean }>`
  cursor: pointer;
  border-bottom: 1px solid ${theme.color.border.border01};
  ${(props) =>
    props.isSelected ? `background-color: ${theme.color.background.ui03};` : ""}

  &:hover {
    background-color: ${theme.color.background.ui03};
  }
`;

function filterByName<T extends { name: string }>(
  options: T[],
  filter: string
) {
  if (filter === "") {
    return options;
  }

  return options.filter(({ name }) =>
    name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
  );
}
