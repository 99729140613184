/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { TreeNode } from "./TreeNode";
import { Node, TreeEvent } from "./types";

type Props = {
  data: Node | Node[];
  onCheck: (event: TreeEvent) => void;
  disabled: boolean;
  disableExpanding?: boolean;
};

export function TreeSelector({
  onCheck,
  disabled,
  data,
  disableExpanding,
}: Props) {
  const content = Array.isArray(data) ? (
    data.map((node) => (
      <TreeNode
        key={node.id}
        data={node}
        onCheck={onCheck}
        disabled={disabled}
        disableExpanding={disableExpanding}
      />
    ))
  ) : (
    <TreeNode
      data={data}
      onCheck={onCheck}
      disabled={disabled}
      disableExpanding={disableExpanding}
    />
  );

  return <TreeNodeWrapper>{content}</TreeNodeWrapper>;
}

const TreeNodeWrapper = styled.div`
  width: 100%;
`;
