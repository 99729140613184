/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCloudAccountCostsByChargeTypeQuery } from "~/generated/graphql";
import { useQueryState } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { isNil, isNotEmpty, isNotNil } from "~/tools";
import { ChargeTypeCostsBar } from "../components/ChargeTypeCostsBar";
import { CloudAccountsQueryState, ProviderTab } from "../types";
import { getAccountFilters, isKubernetesOrOpenshiftTab } from "../utils";
import { useK8sOcTotalsByProvider } from "./useK8sOcTotalsByProvider";

type Props = {
  movedToK8s?: number;
  movedToOc?: number;
};

export function TotalCostsByChargeTypeBar({ movedToK8s, movedToOc }: Props) {
  const { state } = useQueryState<CloudAccountsQueryState>();
  const { provider, status, query, providerFilter } = state;

  const { k8sOc, eks, aks, gke } = useK8sOcTotalsByProvider({
    query: query?.toString(),
    status,
    provider,
    providerFilter,
  });

  const { data } = useCloudAccountCostsByChargeTypeQuery({
    skip: isNil(provider),
    variables: {
      query: query?.toString(),
      filter: {
        ...getAccountFilters(provider, providerFilter),
        status,
      },
    },
  });

  const costsByChargeType =
    data?.cloudAccountCostsByChargeType?.costsByChargeType ?? [];
  const chargeTypeCosts = isKubernetesOrOpenshiftTab(provider)
    ? []
    : costsByChargeType
        ?.map(({ chargeType, cost }) => ({
          chargeType,
          cost,
        }))
        .filter(({ chargeType }) => isNotEmpty(chargeType));

  const showCloudTotals = isKubernetesOrOpenshiftTab(provider);
  const cloudTotals = showCloudTotals
    ? [k8sOc, eks, aks, gke]
        .map(({ cost, chargeType, icon }) => ({
          chargeType: String(chargeType),
          cost: String(cost),
          icon,
        }))
        .filter(({ cost }) => parseFloat(cost) > 0)
    : [];

  const providerFilterQueryString = [
    ProviderTab.AWS,
    ProviderTab.AZURE,
    ProviderTab.GCP,
  ].includes(provider)
    ? `&providerFilter[0]=${provider}`
    : "";

  const movedToK8sCosts =
    isNotNil(movedToK8s) && movedToK8s > 0
      ? [
          {
            chargeType: "Transferred to Kubernetes",
            cost: String(movedToK8s),
            icon: "kubernetes",
            targetLink: {
              pathname: ROUTES.cloudAccounts.index,
              search: `?provider=${ProviderTab.KUBERNETES}${providerFilterQueryString}`,
            },
            ignoreInTotalCost: true,
          },
        ]
      : [];

  const movedToOcCosts =
    isNotNil(movedToOc) && movedToOc > 0
      ? [
          {
            chargeType: "Transferred to OpenShift",
            cost: String(movedToOc),
            icon: "openshift",
            targetLink: {
              pathname: ROUTES.cloudAccounts.index,
              search: `?provider=${ProviderTab.OPENSHIFT}${providerFilterQueryString}`,
            },
            ignoreInTotalCost: true,
          },
        ]
      : [];

  return (
    <ChargeTypeCostsBar
      showTitle={false}
      innerSpacing="spacing00"
      provider={provider}
      chargeTypeCosts={[
        ...chargeTypeCosts,
        ...cloudTotals,
        ...movedToK8sCosts,
        ...movedToOcCosts,
      ]}
    />
  );
}
