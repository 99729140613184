/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  GcpBillingDataDocument,
  GcpBillingDataQuery,
  GcpBillingDataQueryVariables,
  useUpdateGcpBillingConfigMutation,
} from "~/generated/graphql";
import { UpdateFormData } from "../types";

export function useUpdateGcpBillingData() {
  const [updateBillingDataItem, { client, ...mutationState }] =
    useUpdateGcpBillingConfigMutation();

  const updateGcpBillingData = async (data: UpdateFormData) => {
    const response = await updateBillingDataItem({
      variables: {
        input: {
          onboardedOrgId: data.onboardedOrgId,
          serviceAccountKey: data.serviceAccountKey,
          projectId: data.projectId,
          bigQueryDataset: data.bigQueryDataset,
          bigQueryTable: data.bigQueryTable,
          useDetailedBillingData: data.useDetailedBillingData,
        },
      },
    });

    if (response.data?.updateGcpBillingConfig) {
      client.cache.updateQuery<
        GcpBillingDataQuery,
        GcpBillingDataQueryVariables
      >(
        {
          query: GcpBillingDataDocument,
        },
        (billingData) => ({
          gcpBillingData: [
            ...(billingData?.gcpBillingData ?? []),
            { ...data, isActive: false, isOnboarding: false },
          ],
        })
      );
    }
  };

  return [updateGcpBillingData, mutationState] as const;
}
