/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text, theme } from "@nordcloud/gnui";
import {
  FilterWrapper,
  InputLoader,
  SelectableListWithSearch,
} from "~/components";
import { isArrayFilterSelected } from "~/utils";
import { isDoubleFilterSelected, isExcluded } from "../helpers";
import { useDataOptions, useFilters } from "../hooks";
import { EstateMultiTagsInput } from "../MultiTagsInput";
import { DiscoveryTimeFilter } from "./DiscoveryTimeFilter";
import { LifeSpanInput } from "./LifeSpanInput";
import { ProvidersFilter } from "./ProvidersFilter";
import { SidebarFilterProps } from "./types";

export function FiltersByResourceAttribute({
  filters,
  updateFilters,
}: SidebarFilterProps) {
  const {
    clearProviderFilter,
    clearRegionFilter,
    clearAccountFilter,
    clearCategoryFilter,
    clearTypeSubtypeFilter,
    clearTagsFilter,
    clearMetadataFilter,
    clearDiscoveryTimeFilter,
    isLifeSpanFilterSelected,
    clearLifeSpanFilter,
  } = useFilters({
    filters,
    updateFilters,
  });

  const {
    regionOptions,
    loadingAccounts,
    accountsOptions,
    loadingCategories,
    categories,
    loadingResourceTypes,
    resourceTypes,
  } = useDataOptions(filters);

  const selectedAccounts = (filters.account ?? []).map((account) =>
    account.toString()
  );

  return (
    <>
      <Text weight="medium" marginTop={theme.spacing.spacing04}>
        Filters by resource attributes
      </Text>
      <FilterWrapper
        name="Cloud Providers"
        isSelected={isArrayFilterSelected(filters.provider)}
        selectedOptions={filters.provider ?? []}
        onClear={clearProviderFilter}
      >
        <ProvidersFilter
          selectedProviders={filters.provider ?? []}
          isExcludeSelected={filters.providerExclude}
          onProviderSelect={(provider) => updateFilters({ provider })}
          onModeChange={(mode) =>
            updateFilters({ providerExclude: isExcluded(mode) })
          }
        />
      </FilterWrapper>
      <FilterWrapper
        name="Region"
        isSelected={isArrayFilterSelected(filters.region)}
        selectedOptions={filters.region ?? []}
        onClear={clearRegionFilter}
      >
        <SelectableListWithSearch
          allowModeChange
          selectedOptions={filters.region ?? []}
          selectableItems={regionOptions}
          isExcludeSelected={filters.regionExclude}
          onMultiSelect={(region) => updateFilters({ region })}
          onModeChange={(mode) =>
            updateFilters({
              regionExclude: isExcluded(mode),
            })
          }
        />
      </FilterWrapper>
      <FilterWrapper
        name="Cloud Accounts"
        isSelected={isArrayFilterSelected(selectedAccounts)}
        selectedOptions={selectedAccounts}
        onClear={clearAccountFilter}
      >
        <InputLoader loading={loadingAccounts}>
          <SelectableListWithSearch
            allowModeChange
            selectedOptions={filters.account ?? []}
            isExcludeSelected={filters.accountExclude}
            selectableItems={accountsOptions.map((account) => ({
              value: account.value,
              label: account.label,
            }))}
            onMultiSelect={(account) => updateFilters({ account })}
            onModeChange={(mode) =>
              updateFilters({
                accountExclude: isExcluded(mode),
              })
            }
          />
        </InputLoader>
      </FilterWrapper>
      <FilterWrapper
        name="Resource Categories"
        selectedOptions={filters.category ?? []}
        isSelected={isArrayFilterSelected(filters.category)}
        onClear={clearCategoryFilter}
      >
        <InputLoader loading={loadingCategories}>
          <SelectableListWithSearch
            allowModeChange
            selectedOptions={filters.category ?? []}
            selectableItems={categories}
            isExcludeSelected={filters.categoryExclude}
            onMultiSelect={(category) => updateFilters({ category })}
            onModeChange={(mode) =>
              updateFilters({
                categoryExclude: isExcluded(mode),
              })
            }
          />
        </InputLoader>
      </FilterWrapper>
      <FilterWrapper
        name="Resource Types"
        selectedOptions={filters.typeSubtype ?? []}
        isSelected={isArrayFilterSelected(filters.typeSubtype)}
        onClear={clearTypeSubtypeFilter}
      >
        <InputLoader loading={loadingResourceTypes}>
          <SelectableListWithSearch
            allowModeChange
            selectedOptions={filters.typeSubtype ?? []}
            selectableItems={resourceTypes}
            isExcludeSelected={filters.typeSubtypeExclude}
            onMultiSelect={(typeSubtype) => updateFilters({ typeSubtype })}
            onModeChange={(mode) =>
              updateFilters({
                typeSubtypeExclude: isExcluded(mode),
              })
            }
          />
        </InputLoader>
      </FilterWrapper>
      <FilterWrapper
        name="Resource Tag Name And Tag Value"
        selectedOptions={filters.tags}
        isSelected={isArrayFilterSelected(filters.tags)}
        onClear={clearTagsFilter}
      >
        <EstateMultiTagsInput
          inputTags={filters.tags}
          placeholderName="Tag"
          isExcludeSelected={filters.tagsExclude}
          onChange={(tags) =>
            updateFilters({
              tags,
            })
          }
          onModeChange={(mode) =>
            updateFilters({
              tagsExclude: isExcluded(mode),
            })
          }
        />
      </FilterWrapper>
      <FilterWrapper
        name="Resource Metadata Key And Value"
        selectedOptions={filters.metadata ?? []}
        isSelected={isArrayFilterSelected(filters.metadata)}
        onClear={clearMetadataFilter}
      >
        <EstateMultiTagsInput
          inputTags={filters.metadata}
          placeholderName="Metadata"
          isExcludeSelected={filters.metadataExclude}
          onChange={(metadata) =>
            updateFilters({
              metadata,
            })
          }
          onModeChange={(mode) =>
            updateFilters({
              metadataExclude: isExcluded(mode),
            })
          }
        />
      </FilterWrapper>
      <FilterWrapper
        name="Discovery Time"
        isSelected={isDoubleFilterSelected(
          filters.firstSeenAtFrom,
          filters.firstSeenAtTo
        )}
        onClear={clearDiscoveryTimeFilter}
      >
        <DiscoveryTimeFilter
          filters={filters}
          updateFilters={updateFilters}
          isExcludeSelected={filters.firstSeenAtExclude}
          onModeChange={(mode) =>
            updateFilters({
              firstSeenAtExclude: isExcluded(mode),
            })
          }
        />
      </FilterWrapper>
      <FilterWrapper
        name="Resource Life Span"
        isSelected={isLifeSpanFilterSelected}
        onClear={clearLifeSpanFilter}
      >
        <LifeSpanInput
          filters={filters}
          updateFilters={updateFilters}
          size="m"
          isExcludeSelected={filters.lifeSpanExclude}
          onModeChange={(mode) =>
            updateFilters({
              lifeSpanExclude: isExcluded(mode),
            })
          }
        />
      </FilterWrapper>
    </>
  );
}
