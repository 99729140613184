/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Sidebar } from "@nordcloud/gnui";
import { isKubernetesOrOpenshift, isVMWare } from "~/views/cloudAccounts/utils";
import { Account } from "../../types";
import { KubernetesOpenshiftDocumentationForm } from "./KubernetesOpenshiftDocumentationForm";
import { VmwareDocumentationForm } from "./VmwareDocumentationForm";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  account: Account;
};

export function HybridCloudDocumentationSidebar({
  isOpen,
  onClose,
  account,
}: Props) {
  return (
    <Sidebar
      width="47rem"
      isOpen={isOpen}
      title="Onboarding Documentation"
      onClick={onClose}
    >
      <When condition={isVMWare(account.providerType)}>
        <VmwareDocumentationForm account={account} />
      </When>

      <When condition={isKubernetesOrOpenshift(account.providerType)}>
        <KubernetesOpenshiftDocumentationForm />
      </When>
    </Sidebar>
  );
}
