/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import {
  Button,
  FlexContainer,
  Input,
  Label,
  List,
  Text,
  theme,
} from "@nordcloud/gnui";
import { ExternalLink, FormGroup } from "~/components";
import { ContactsSelector } from "~/components/Inputs/ContactsSelector/ContactsSelector";
import { ERROR_TEXT, SUCCESS_TEXT } from "~/constants";
import { showError, showSuccess } from "~/services/toast";
import { FormHint } from "../../components/FormHint";
import {
  IBM_CLOUD_API_KEYS,
  IBM_CLOUD_IAM,
  IBM_CLOUD_NOTIFICATION,
  IBM_CLOUD_PLATFORM,
} from "../../constants";
import { IbmCloudFormFields, Provider, ProviderTab } from "../../types";
import { AdditionalCloudAccountSettings } from "./AdditionalCloudAccountSettings";
import { IbmCloudFormData } from "./types";
import { useAddAccount } from "./useAddAccount";
import { addIbmCloudSchema } from "./validators";

type Props = {
  closeSidebar: () => void;
};

export function IbmCloudSidebarContent({ closeSidebar }: Props) {
  const formMethods = useForm<IbmCloudFormData>({
    resolver: yupResolver(addIbmCloudSchema),
  });

  const { addAccount, loading } = useAddAccount(ProviderTab.IBMCLOUD);

  const {
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = formMethods;

  const instructionsList = [
    "Select Manage, then Access(IAM), and then click User from the left menu. Select proxy account, then click Access policies on the top tab, and then click Assign access",
    "On Assign access page, select IAM services, then select All Identity and Access enabled services. For How do you want to scope the access?, select All resources. Then select Viewer and Reader for Platform access, Service access, and Resource group access. Then, click Add.",
    "Select Account Management, then All Account management Service, and then select Viewer access. Then, click Add.",
    "Select Cloud foundry, then select current organization, and then select Auditor access for the Organization role, and for the Space role. Click Add, then click Assign on the right.",
    "Select Class infrastructure, then click Manage user infrastructure access page, and then select view only in Permission sets. Then, click Apply in the Permissions tag. Then, select all options in Select type, and select Enable future access in the Devices tag.",
  ].map((title) => ({ title }));

  const onAddAccountSubmit = async (formData: IbmCloudFormData) => {
    if (loading) {
      return;
    }

    try {
      await addAccount({
        variables: {
          input: {
            creds: JSON.stringify({ apikey: formData.apiKey }),
            name: formData.accountId,
            ownerId: formData.accountOwner,
            displayName: formData.displayName,
            description: formData.description,
            contactIds: formData.contactPerson
              ? [formData.contactPerson]
              : undefined,
            provider: Provider.Ibmcloud,
            providerId: formData.accountId,
          },
        },
      });
      showSuccess(SUCCESS_TEXT.accountAdded);
      closeSidebar();
    } catch {
      showError(ERROR_TEXT.failedAddingAccount);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form id="add-ibmcloud-form" onSubmit={handleSubmit(onAddAccountSubmit)}>
        <FormGroup error={errors[IbmCloudFormFields.ACCOUNT_ID]}>
          <Label
            required
            htmlFor={IbmCloudFormFields.ACCOUNT_ID}
            name="Provide Main Account ID"
          />
          <Input
            placeholder="e.g., 123456789012"
            {...register(IbmCloudFormFields.ACCOUNT_ID)}
          />
        </FormGroup>
        <FormGroup error={errors[IbmCloudFormFields.ACCOUNT_OWNER] ?? []}>
          <Label
            required
            htmlFor={IbmCloudFormFields.ACCOUNT_OWNER}
            name="Account Owner"
          />
          <ContactsSelector
            onChange={(selectedContact) =>
              setValue(IbmCloudFormFields.ACCOUNT_OWNER, selectedContact)
            }
          />
        </FormGroup>
        <AdditionalCloudAccountSettings />
        <Text weight="bold">Account Credentials</Text>
        <FormHint stepNumber={1}>
          <Text>
            Sign in to{" "}
            <ExternalLink href={IBM_CLOUD_PLATFORM}>
              IBM Cloud Platform
            </ExternalLink>{" "}
            with the credentials of the main account.
          </Text>
        </FormHint>
        <FormHint stepNumber={2}>
          <Text>
            On the{" "}
            <ExternalLink href={IBM_CLOUD_IAM}>Access (IAM) Users</ExternalLink>{" "}
            page, invite the proxy account.
          </Text>
        </FormHint>
        <FormHint stepNumber={3}>
          <Text>
            In a separate browser, sign in to{" "}
            <ExternalLink href={IBM_CLOUD_PLATFORM}>
              IBM Cloud Platform
            </ExternalLink>{" "}
            with proxy account. Then on the{" "}
            <ExternalLink href={IBM_CLOUD_NOTIFICATION}>
              IBM Cloud Notification
            </ExternalLink>{" "}
            page, accept the invitation you sent from the main account.
          </Text>
        </FormHint>
        <FormHint stepNumber={4}>
          <Text>
            On the Users page of the main account, grant <strong>Reader</strong>{" "}
            access and <strong>Viewer </strong>
            access, to the proxy account in main account page
          </Text>
        </FormHint>
        <List unordered items={instructionsList} />
        <Text ml={theme.spacing.spacing07} mb={theme.spacing.spacing03}>
          As a result, on the Access Policies tab, you now see multiple Viewer
          roles.
        </Text>
        <FormHint stepNumber={5}>
          <Text>
            Switch to the page for the proxy account, and create the API Key.
            For more information, see{" "}
            <ExternalLink href={IBM_CLOUD_API_KEYS}>
              API Keys Document
            </ExternalLink>
            .
          </Text>
        </FormHint>
        <FormGroup error={errors[IbmCloudFormFields.API_KEY]}>
          <Label
            required
            htmlFor={IbmCloudFormFields.API_KEY}
            name="Provide The API Key From The Proxy Account"
          />
          <Input
            placeholder="e.g., XEogykgnehnuMKZUEM-abFxjK7uCNF9FOdBRI9-KgTMR"
            {...register(IbmCloudFormFields.API_KEY)}
          />
        </FormGroup>
        <FlexContainer columnGap={theme.spacing.spacing03}>
          <Button
            icon="checkmark"
            initialState={loading ? "loading" : ""}
            css={{ border: 0 }}
            type="submit"
            form="add-ibmcloud-form"
          >
            Apply
          </Button>
          <Button severity="low" type="button" onClick={closeSidebar}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}
