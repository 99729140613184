/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  WorkflowExternalIntegrationType,
  WorkflowTriggerType,
} from "~/generated/graphql";
import { WorkflowDataType, WorkflowSidebars } from "../constants";
import { getDefaultValues } from "../WorkflowDetails/helpers";
import { WorkflowProps } from "../WorkflowPropsMapping";
import {
  isAppsAndEnvsEmpty,
  isEmailEmpty,
  isSchedulerEmpty,
  isServiceNowEmpty,
  isSlackEmpty,
} from "./utils";
import { SendDataComponentConfig, TriggerConfig } from "./WorkflowCanvas";

type MapTriggerComponentsProps = {
  types: WorkflowTriggerType[];
  setOpenSidebar: (sidebar: WorkflowSidebars) => void;
  workflow?: WorkflowProps;
};

export function mapTriggerComponents({
  types,
  setOpenSidebar,
  workflow,
}: MapTriggerComponentsProps): TriggerConfig[] {
  return types.map((type) => {
    if (type === WorkflowTriggerType.Scheduler) {
      const defaultScheduler = workflow && getDefaultValues(workflow).scheduler;

      return {
        type,
        onSettingsClick: () => setOpenSidebar(WorkflowSidebars.SCHEDULER),
        defaultSchedulerValues: isSchedulerEmpty(defaultScheduler)
          ? undefined
          : defaultScheduler,
      };
    }

    return {
      type,
    };
  });
}

type MapSelectDataComponentProps = {
  type?: WorkflowDataType;
  setOpenSidebar: (sidebar: WorkflowSidebars) => void;
  workflow?: WorkflowProps;
  isReadMode?: boolean;
};

export function mapSelectDataComponent({
  type,
  setOpenSidebar,
  workflow,
  isReadMode = false,
}: MapSelectDataComponentProps) {
  switch (type) {
    case WorkflowDataType.SAVING_SUGGESTIONS: {
      const defaultSavingsSuggestions =
        workflow && getDefaultValues(workflow).savingsSuggestions;

      return {
        type,
        onSettingsClick: () =>
          setOpenSidebar(WorkflowSidebars.SAVINGS_SUGGESTIONS),
        defaultSavingsSuggestions: isAppsAndEnvsEmpty(defaultSavingsSuggestions)
          ? undefined
          : defaultSavingsSuggestions,
      };
    }
    case WorkflowDataType.KPI:
      return {
        type,
        onSettingsClick: isReadMode
          ? undefined
          : () => setOpenSidebar(WorkflowSidebars.KPI),
      };
    case WorkflowDataType.COSTS: {
      const defaultCostsOptions =
        workflow && getDefaultValues(workflow).costsOptions;

      return {
        type,
        onSettingsClick: () => setOpenSidebar(WorkflowSidebars.COSTS),
        defaultSavingsSuggestions: isAppsAndEnvsEmpty(defaultCostsOptions)
          ? undefined
          : defaultCostsOptions,
      };
    }
    case WorkflowDataType.BUDGETS:
      return {
        type,
        onSettingsClick: () => setOpenSidebar(WorkflowSidebars.BUDGETS),
      };
    case WorkflowDataType.UNALLOCATED_RESOURCES:
      return { type };
    case WorkflowDataType.ANOMALY_COSTS:
      return {
        type,
        onSettingsClick: () => setOpenSidebar(WorkflowSidebars.ANOMALY_COSTS),
      };
    default:
      return undefined;
  }
}

type MapSendDataComponentsProps = {
  types: WorkflowExternalIntegrationType[];
  workflow?: WorkflowProps;
  setOpenSidebar: (sidebar: WorkflowSidebars) => void;
};

export function mapSendDataComponents({
  types,
  workflow,
  setOpenSidebar,
}: MapSendDataComponentsProps): SendDataComponentConfig[] {
  return types.map((type) => {
    switch (type) {
      case WorkflowExternalIntegrationType.ServiceNow: {
        const defaultServiceNow =
          workflow && getDefaultValues(workflow).serviceNow;

        return {
          type,
          onSettingsClick: () => setOpenSidebar(WorkflowSidebars.SERVICENOW),
          defaultServiceNowValues: isServiceNowEmpty(defaultServiceNow)
            ? undefined
            : defaultServiceNow,
        };
      }
      case WorkflowExternalIntegrationType.Email: {
        const defaultEmail = workflow && getDefaultValues(workflow).email;

        return {
          type,
          onSettingsClick: () => setOpenSidebar(WorkflowSidebars.EMAIL),
          defaultEmailValues: isEmailEmpty(defaultEmail)
            ? undefined
            : defaultEmail,
        };
      }
      case WorkflowExternalIntegrationType.Slack: {
        const defaultSlack = workflow && getDefaultValues(workflow).slack;

        return {
          type,
          onSettingsClick: () => setOpenSidebar(WorkflowSidebars.SLACK),
          defaultSlackValues: isSlackEmpty(defaultSlack)
            ? undefined
            : defaultSlack,
        };
      }
      default:
        return {
          type,
        };
    }
  });
}
