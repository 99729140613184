/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState, useMemo, useCallback, useEffect } from "react";
import {
  ChartJobQuery,
  Granularity,
  SearchJobStatus,
  useChartJobQuery,
  useSubmitChartJobMutation,
} from "~/generated/graphql";
import { isEmpty, isNotEmpty } from "~/tools";

const POLL_INTERVAL_MS = 1000; // 1 second
const POLL_INTERVAL_ON_FAIL_MS = 30_000; // 30 seconds

const ERROR_MESSAGE = "Sorry, something went wrong.";

export function useEstateRecordsChartJob(
  queryParams: Parameters<typeof useSubmitChartJobMutation>[0] & {
    skip?: boolean;
  },
  onError: (message: string) => void
) {
  const [chartJobId, setChartJobId] = useState("");
  const [results, setResults] = useState<ChartJobQuery | undefined>(undefined);
  const [errorCount, setErrorCount] = useState(0);

  const [submitChartJob, { loading: isSubmitLoading }] =
    useSubmitChartJobMutation({
      ...queryParams,
      onCompleted: (result) =>
        setChartJobId(result?.submitChartJobV2?.jobId ?? ""),
    });

  const {
    data,
    loading: isChartJobLoading,
    error,
    startPolling,
    stopPolling,
  } = useChartJobQuery({
    variables: {
      jobId: chartJobId,
      dailyGranularity: Granularity.Daily,
      monthlyGranularity: Granularity.Monthly,
    },
    pollInterval: POLL_INTERVAL_MS,
    notifyOnNetworkStatusChange: true,
    skip: isEmpty(chartJobId),
    onCompleted: (result) => {
      if (result.chartJob?.status === SearchJobStatus.Completed) {
        handleDataResponse(data);
      }

      if (result.chartJob?.status === SearchJobStatus.Failed) {
        handleDataResponse(data);
        onError(ERROR_MESSAGE);
      }
    },
    onError: () => {
      stopPolling();
      if (errorCount < 4) {
        setErrorCount((prevValue) => prevValue + 1);
        startPolling(POLL_INTERVAL_ON_FAIL_MS);
      } else {
        setErrorCount(0);
      }
    },
  });

  const handleDataResponse = (response: ChartJobQuery | undefined) => {
    setResults(response);
    setChartJobId("");
    stopPolling();
  };

  type SubmitChartParams = Parameters<typeof submitChartJob>[0];

  const refetch = useCallback(
    (
      params?: Omit<Parameters<typeof submitChartJob>[0], "variables"> & {
        variables?: Partial<NonNullable<SubmitChartParams>["variables"]>;
      }
    ) => {
      void submitChartJob({
        variables: {
          filter: {
            ...queryParams?.variables?.filter,
            ...params?.variables?.filter,
          },
          startDate: queryParams?.variables?.startDate ?? "",
          endDate: queryParams?.variables?.endDate ?? "",
          chartType: queryParams?.variables?.chartType,
        },
      });
    },
    // `queryParams` might get recreated on every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(queryParams), submitChartJob]
  );

  const isLoading =
    !error && (isChartJobLoading || isSubmitLoading || isNotEmpty(chartJobId));

  useEffect(
    () => {
      if (queryParams?.skip) {
        return;
      }

      setErrorCount(0);
      refetch(queryParams);
    },
    // `queryParams` might get recreated on every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(queryParams), submitChartJob]
  );

  return useMemo(
    () => ({
      refetch,
      loading: isLoading,
      chartData: results,
      error,
    }),
    [refetch, results, isLoading, error]
  );
}
