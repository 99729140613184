/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { FlexContainer, theme, Box, Text } from "@nordcloud/gnui";
import { isNotNil } from "~/tools";

type Props = {
  stepNumber: number;
  title: JSX.Element;
  children?: JSX.Element;
};

export function StepContainer({ stepNumber, title, children }: Props) {
  return (
    <FlexContainer
      css={{ width: "100%" }}
      alignItems="flex-start"
      gap={theme.spacing.spacing03}
    >
      <Box
        boxStyle="grey"
        p={theme.spacing.spacing01}
        css={{ height: "2rem", width: "2rem", minWidth: "2rem" }}
      >
        <FlexContainer justifyContent="center" alignItems="center">
          <Text tag="div" weight="bold">
            {stepNumber}
          </Text>
        </FlexContainer>
      </Box>
      <div css={{ width: "100%", paddingTop: theme.spacing.spacing01 }}>
        {title}
        <When condition={isNotNil(children)}>{children}</When>
      </div>
    </FlexContainer>
  );
}
