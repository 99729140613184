/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useApolloClient } from "@apollo/client";
import {
  AwsBillingDataDocument,
  AwsBillingDataQuery,
  AwsBillingDataQueryVariables,
  useAddAwsCapacityMutation,
} from "~/generated/graphql";
import { ERROR_TEXT } from "~/constants";
import { FormData } from "~/views/cloudAccounts/BillingData/aws/validators";

export function useAddAwsBillingData() {
  const client = useApolloClient();
  const [addAwsCapacity, { loading, error }] = useAddAwsCapacityMutation();

  const addAwsBillingData = async (data: FormData) => {
    const response = await addAwsCapacity({ variables: { input: data } });

    if (response.data?.addAWSCapacity?.success) {
      client.cache.updateQuery<
        AwsBillingDataQuery,
        AwsBillingDataQueryVariables
      >(
        {
          query: AwsBillingDataDocument,
        },
        (billingData) => ({
          awsBillingData: [
            ...(billingData?.awsBillingData ?? []),
            { accountId: data.accountId, isActive: false, isOnboarding: false },
          ],
        })
      );

      return;
    }

    throw new Error(ERROR_TEXT.default);
  };

  return { addAwsBillingData, loading, error };
}
