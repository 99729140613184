/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const Ul = styled.ul`
  list-style-type: square;
  padding-left: ${theme.spacing.spacing04};
`;
