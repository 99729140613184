/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const costPerOrgUnitMock = {
  data: {
    chartJob: {
      status: "COMPLETED",
      data: {
        total: "2178.780029559",
        timePoints: [
          {
            date: "2023-07-01",
            value: "597.379746714",
            groups: [
              {
                id: "orgUnit1",
                name: "orgUnit1",
                value: "97.379746714",
              },
              {
                id: "orgUnit2",
                name: "orgUnit2",
                value: "500",
              },
            ],
          },
          {
            date: "2023-07-02",
            value: "241.868653467",
            groups: [
              {
                id: "orgUnit1",
                name: "orgUnit1",
                value: "241.868653467",
              },
              {
                id: "correction",
                name: "correction",
                value: "-100.597227999",
              },
            ],
          },
          {
            date: "2023-07-03",
            value: "357.405880315",
            groups: [
              {
                id: "orgUnit1",
                name: "orgUnit1",
                value: "357.405880315",
              },
            ],
          },
          {
            date: "2023-07-04",
            value: "562.324192602",
            groups: [
              {
                id: "orgUnit1",
                name: "orgUnit1",
                value: "562.324192602",
              },
            ],
          },
          {
            date: "2023-07-05",
            value: "419.801556461",
            groups: [
              {
                id: "orgUnit1",
                name: "orgUnit1",
                value: "419.801556461",
              },
            ],
          },
        ],
      },
    },
    chartMonthlyJob: {
      status: "COMPLETED",
      data: {
        total: "2178.780029559",
        timePoints: [
          {
            date: "2023-07-01",
            value: "2178.780029559",
            groups: [
              {
                id: "orgUnit1",
                name: "orgUnit1",
                value: "1678.780029559",
              },
              {
                id: "orgUnit2",
                name: "orgUnit2",
                value: "500",
              },
              {
                id: "correction",
                name: "correction",
                value: "-100.597227999",
              },
            ],
          },
        ],
      },
    },
  },
};

export const costPerEnvMock = {
  data: {
    chartJob: {
      status: "COMPLETED",
      data: {
        total: "2178.780029559",
        timePoints: [
          {
            date: "2023-07-01",
            value: "597.379746714",
            groups: [
              {
                id: "env1",
                name: "env1",
                value: "97.379746714",
              },
              {
                id: "env2",
                name: "env2",
                value: "500",
              },
            ],
          },
          {
            date: "2023-07-02",
            value: "241.868653467",
            groups: [
              {
                id: "env1",
                name: "env1",
                value: "241.868653467",
              },
              {
                id: "correction",
                name: "correction",
                value: "-100.597227999",
              },
            ],
          },
          {
            date: "2023-07-03",
            value: "357.405880315",
            groups: [
              {
                id: "env1",
                name: "env1",
                value: "357.405880315",
              },
            ],
          },
          {
            date: "2023-07-04",
            value: "562.324192602",
            groups: [
              {
                id: "env1",
                name: "env1",
                value: "562.324192602",
              },
            ],
          },
          {
            date: "2023-07-05",
            value: "419.801556461",
            groups: [
              {
                id: "env1",
                name: "env1",
                value: "419.801556461",
              },
            ],
          },
        ],
      },
    },
    chartMonthlyJob: {
      status: "COMPLETED",
      data: {
        total: "2178.780029559",
        timePoints: [
          {
            date: "2023-07-01",
            value: "2178.780029559",
            groups: [
              {
                id: "env1",
                name: "env1",
                value: "1678.780029559",
              },
              {
                id: "env2",
                name: "env2",
                value: "500",
              },
              {
                id: "correction",
                name: "correction",
                value: "-100.597227999",
              },
            ],
          },
        ],
      },
    },
  },
};
