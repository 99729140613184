/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { lazy, LazyExoticComponent, ComponentType } from "react";

/**
 * Chunk loading handler with retry (page refresh) mechanism
 */
export function lazyWithRetry<T extends ComponentType<any>>(
  componentImport: () => Promise<{ default: T }>,
  chunkName: string
): LazyExoticComponent<T> {
  return lazy(async () => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
    ) as boolean;

    try {
      const component = await componentImport();

      window.sessionStorage.setItem(`retry-${chunkName}-refreshed`, "false");

      return component;
    } catch (error) {
      console.error(error);

      if (!hasRefreshed) {
        window.sessionStorage.setItem(`retry-${chunkName}-refreshed`, "true");
        return window.location.reload() as unknown as { default: T };
      }

      throw error;
    }
  });
}
