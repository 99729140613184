/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, theme, Text } from "@nordcloud/gnui";
import { AccountV2Query } from "~/generated/graphql";
import DownloadDescriptorButton from "~/views/accounts/DownloadDescriptorButton";
import { CopyBox } from "../../AddAccount/components/CopyBox";
import { getDownloadButtonProps } from "../utils";
import { CopyToClipboard } from "./CopyToClipboard";
import { StepContainer } from "./StepContainer";

type Props = {
  account?: AccountV2Query["accountV2"];
};

export function KubernetesOpenShiftUpdateCredentialsForm({ account }: Props) {
  return (
    <FlexContainer
      css={{ width: "100%" }}
      alignItems="flex-start"
      direction="column"
      gap={theme.spacing.spacing03}
    >
      <Text color={theme.color.text.text02} size="sm">
        You can onboard to IBM&nbsp;Multicloud&nbsp;Accelerator your Kubernetes
        clusters and map your resources to the proper applications and
        environments. Here we prepared easy two-step onboarding process where
        you can create your cluster to be visible in
        IBM&nbsp;Multicloud&nbsp;Accelerator as a first step, and after that you
        deploy simple .yaml file inside your cluster to feed
        IBM&nbsp;Multicloud&nbsp;Accelerator with the data.
      </Text>
      <StepContainer
        stepNumber={1}
        title={
          <DownloadDescriptorButton
            {...getDownloadButtonProps(account, {
              buttonName: "Download Deployment YAML",
              severity: "low",
              icon: "download",
            })}
          />
        }
      />
      <StepContainer
        stepNumber={2}
        title={<Text>Setting the scanner inside the Kubernetes cluster.</Text>}
      >
        <Text color={theme.color.text.text02} size="sm">
          This file needs to be placed in your cluster to send the data to
          IBM&nbsp;Multicloud&nbsp;Accelerator. After placing the Deployment
          YAML into your Kubernetes cluster it might take up to 30 minutes for
          IBM&nbsp;Multicloud&nbsp;Accelerator to highlight your cluster with
          the green icon and get first data.
        </Text>
      </StepContainer>

      <Text weight="bold" tag="div">
        Update Scanner
      </Text>
      <CopyBox>kubectl rollout restart deployment nordcloud-scanner</CopyBox>
      <Text weight="bold">
        Configure Kubernetes Scanner to Use Existing Prometheus Instance
      </Text>
      <Text tag="div" weight="medium">
        Configuration
      </Text>
      <Text tag="div">
        Modify the prometheus-endpoint property in the
        nordcloud-metrics-exporter-config-map config map (default namespace:
        nordcloud-scanner) and set your prometheus instance endpoint.
      </Text>
      <Text weight="medium" tag="div">
        Clean Up
      </Text>
      <Text tag="div">
        Remove prometheus resources (cluster role, cluster role binding, config
        map, deployment and service) that are no longer needed:
      </Text>
      <CopyToClipboard
        valueToCopy="kubectl delete deployment/nordcloud-prometheus-deployment -n
              nordcloud-scanner kubectl delete service/nordcloud-prometheus-service -n
              nordcloud-scanner kubectl delete configmap/nordcloud-prometheus-config-map -n
              nordcloud-scanner kubectl delete clusterRoleBinding nordcloud-prometheus kubectl delete clusterRole nordcloud-prometheus"
        value={
          <>
            <Text nowrap tag="div">
              kubectl delete deployment/nordcloud-prometheus-deployment -n
              nordcloud-scanner
            </Text>
            <Text nowrap tag="div">
              kubectl delete service/nordcloud-prometheus-service -n
              nordcloud-scanner
            </Text>
            <Text nowrap tag="div">
              kubectl delete configmap/nordcloud-prometheus-config-map -n
              nordcloud-scanner
            </Text>
            <Text nowrap tag="div">
              kubectl delete clusterRoleBinding nordcloud-prometheus
            </Text>
            <Text nowrap tag="div">
              kubectl delete clusterRole nordcloud-prometheus
            </Text>
          </>
        }
      />
    </FlexContainer>
  );
}
