/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import {
  FlexContainer,
  theme,
  Text,
  SVGIcon,
  Button,
  Message,
  Spacer,
} from "@nordcloud/gnui";
import { ExternalLink } from "~/components";
import { toTitleCase } from "~/views/cloudAccounts/utils";
import { Account } from "../../types";
import { HybridCloudDownloadButton } from "../HybridCloudDownloadButton";
import { StepContainer } from "./StepContainer";

type Props = {
  account: Account;
};

const SquareBulletList = styled.ul`
  list-style-type: square;
  padding-left: ${theme.spacing.spacing04};
`;

const DashBulletList = styled.ul`
  list-style-type: "- ";
  padding-left: ${theme.spacing.spacing04};
  color: ${theme.color.text.text02};
`;

export function VmwareDocumentationForm({ account }: Props) {
  return (
    <div css={{ width: "100%" }}>
      <Message status="notification" image="info" width={100}>
        <div css={{ width: "100%" }}>
          <Text
            weight="medium"
            color={theme.color.text.info}
            mb={theme.spacing.spacing00}
          >
            To run the VMware Scanner, you must:
          </Text>
          <DashBulletList>
            <li>
              Download and install the Scanner in your VMware Environment.
            </li>
            <li>Configure and deploy the Scanner.</li>
            <li>Schedule the Scanner to run at regular intervals.</li>
          </DashBulletList>
        </div>
      </Message>
      <Spacer height={theme.spacing.spacing06} />

      <Text weight="bold" size="lg">
        Onboarding
      </Text>

      <StepContainer
        stepNumber={1}
        title={
          <Text>
            Sign in to the In vCenter, create a service user with read-only
            permissions for your VMware installation. Record the username and
            password of this service user because you must add this information
            to the Scanner&apos;s configuration file.
          </Text>
        }
      />
      <StepContainer
        stepNumber={2}
        title={
          <Text>
            To download the relevant binary package (either Windows, or Linux)
            to the virtual machine, click below:
          </Text>
        }
      >
        <FlexContainer gap={theme.spacing.spacing04}>
          <DownloadLink operatingSystem="linux" />
          <DownloadLink operatingSystem="windows" />
        </FlexContainer>
      </StepContainer>
      <Spacer height={theme.spacing.spacing04} />
      <StepContainer
        stepNumber={3}
        title={
          <Text>
            The downloaded binary package includes a configuration file
          </Text>
        }
      >
        <HybridCloudDownloadButton account={account} severity="low" />
      </StepContainer>
      <Spacer height={theme.spacing.spacing04} />
      <StepContainer
        stepNumber={4}
        title={
          <Text>
            In config.json, change the values of the keys, as follows:
          </Text>
        }
      >
        <SquareBulletList>
          <li>
            <b>vmware_api_url</b> - Domain name or IP address of a VMware
            installation (for example, vmware.local).
          </li>
          <li>
            <b>vmware_api_username</b> - Username of created service account.
          </li>
          <li>
            <b>vmware_api_password</b> - Password of created service account.
          </li>
        </SquareBulletList>
      </StepContainer>
      <StepContainer
        stepNumber={5}
        title={
          <Text>Copy config.json to the same location as the binary.</Text>
        }
      />
      <StepContainer
        stepNumber={6}
        title={
          <Text>
            Schedule the binary to run at a certain time during the night, for
            example, 1:00 UTC.
          </Text>
        }
      />
      <StepContainer
        stepNumber={7}
        title={
          <Text>
            After configuration, first run the binary as a test. Verify with
            Nordcloud that the Scanner is importing estate record data as
            expected.
          </Text>
        }
      />
    </div>
  );
}

function DownloadLink({
  operatingSystem,
}: {
  operatingSystem: "linux" | "windows";
}) {
  const { buttonText, link } = {
    buttonText: `Download for ${toTitleCase(operatingSystem)}`,
    link:
      operatingSystem === "linux"
        ? "https://github.com/nordcloud/klarity-scanner-vmware-cli/releases/latest/download/klarity-scanner-vmware-cli-linux-amd64.zip"
        : "https://github.com/nordcloud/klarity-scanner-vmware-cli/releases/latest/download/klarity-scanner-vmware-cli-windows-amd64.zip",
  };
  return (
    <ExternalLink
      href={link}
      css={{
        ":hover": {
          textDecoration: "none",
        },
      }}
    >
      <Button severity="low">
        <FlexContainer>
          <SVGIcon name="download" />
          <Text tag="span">{buttonText}</Text>
        </FlexContainer>
      </Button>
    </ExternalLink>
  );
}
