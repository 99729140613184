/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { ParentSize } from "@visx/responsive";
import {
  BrickLoader,
  ChartType,
  EstatePeriod,
  Granularity,
  mapStackBarChartColors,
  StackBarChart,
  UniversalWrap,
} from "~/components";
import { useEnvironmentsPerType } from "./hooks";

type Props = {
  startDate: string;
  endDate: string;
  granularity: Granularity;
  period: EstatePeriod;
};

export function EstateRecordsEnvironmentType({
  startDate,
  endDate,
  granularity,
  period,
}: Props) {
  const { costPerEnvChartData, environments } = useEnvironmentsPerType({
    granularity,
  });

  const stackBarColors = useMemo(
    () => mapStackBarChartColors(environments),
    [environments]
  );

  return (
    <UniversalWrap
      loaderProps={{ loading: false, Component: BrickLoader }}
      errorProps={{}}
    >
      <ParentSize>
        {(parent) => (
          <StackBarChart
            showOthers={false}
            width={parent.width}
            height={280}
            data={costPerEnvChartData}
            granularity={granularity}
            loading={false}
            startDate={startDate}
            endDate={endDate}
            period={period}
            chartType={ChartType.COST_PER_ENVIRONMENT}
            colors={stackBarColors}
            keys={environments}
          />
        )}
      </ParentSize>
    </UniversalWrap>
  );
}
