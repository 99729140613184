/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box, BrickLoader, Text } from "@nordcloud/gnui";
import { UniversalWrap } from "~/components";
import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { showSuccess, showError } from "~/services/toast";
import { GcpForm } from "./GcpForm";
import { useAddGcpBillingData } from "./hooks/useAddGcpBillingData";
import { FormData } from "./types";

export function AddBillingData({ onClose }: { onClose: () => void }) {
  const [addGcpBillingData, { error, loading }] = useAddGcpBillingData();

  const onSubmit = async (data: FormData) => {
    try {
      await addGcpBillingData(data);
      showSuccess(SUCCESS_TEXT.billingDataAdded);
      onClose();
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  return (
    <Box boxStyle="lightGrey">
      <Text weight="medium">Billing Data Settings</Text>
      <UniversalWrap
        loaderProps={{ loading, Component: BrickLoader }}
        errorProps={{ error }}
      >
        <GcpForm
          defaultValues={{ useDetailedBillingData: false }}
          onCloseForm={onClose}
          onSubmit={onSubmit}
        />
      </UniversalWrap>
    </Box>
  );
}
