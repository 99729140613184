/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo, useState } from "react";
import { ParentSize } from "@visx/responsive";
import dayjs from "dayjs";
import {
  Box,
  FlexContainer,
  MultipleSelect,
  SelectButton,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { useGetGlobalCostAllocationQuery } from "~/generated/graphql";
import { DataContainer, DataItem } from "~/components";
import { dateFormat } from "~/constants";
import { CostAllocationChart } from "./CostAllocationChart";
import { CostAllocationLegend } from "./CostAllocationLegend";

export function CostAllocation() {
  const [period, setPeriod] = useState(Period.THREE_MONTHS);
  const today = dayjs();
  const endDate = today.format(dateFormat.shortDate);
  const startDate = useMemo(
    () => today.subtract(period, "months").format(dateFormat.shortDate),
    [period, today]
  );

  const { data, loading } = useGetGlobalCostAllocationQuery({
    variables: { startDate, endDate },
  });

  const costAllocation = data?.globalMetrics?.costAllocation;

  return (
    <Box spacing="spacing04" mb={theme.spacing.spacing04}>
      <section>
        <DataContainer hasBorder={false}>
          <DataItem
            grow
            padding="0"
            weight="medium"
            size="md"
            value="Cost Allocation"
            description="Allows to track the percentage of the cost allocation in the applications. We record a daily value of % of the allocated total cost."
          />
        </DataContainer>
      </section>
      <Box
        boxStyle="lightGrey"
        p={`${theme.spacing.spacing04} ${theme.spacing.spacing02}`}
      >
        <FlexContainer alignItems="center">
          <Text mb={0} mr={theme.spacing.spacing02}>
            Time period
          </Text>
          <MultipleSelect size="small">
            <SelectButton
              name="quarter"
              value="3"
              labelText="3 months"
              isActive={period === Period.THREE_MONTHS}
              data-testid="quarter"
              style={{
                minWidth: "7rem",
              }}
              onClick={() => setPeriod(Period.THREE_MONTHS)}
            />
            <SelectButton
              name="year"
              value="12"
              labelText="Up to 12 months"
              isActive={period === Period.TWELVE_MONTHS}
              data-testid="year"
              style={{
                minWidth: "7rem",
              }}
              onClick={() => setPeriod(Period.TWELVE_MONTHS)}
            />
          </MultipleSelect>
        </FlexContainer>
      </Box>
      <Spacer height={theme.spacing.spacing02} />
      <ParentSize>
        {(parent) => (
          <CostAllocationChart
            startDate={startDate}
            endDate={endDate}
            width={parent.width}
            height={250}
            data={costAllocation?.timeSeries ?? []}
            loading={loading}
          />
        )}
      </ParentSize>
      <Spacer height={theme.spacing.spacing02} />
      <CostAllocationLegend />
    </Box>
  );
}

const enum Period {
  THREE_MONTHS = 3,
  TWELVE_MONTHS = 12,
}
