/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled, { css } from "styled-components";
import { FlexContainer, theme, Text } from "@nordcloud/gnui";
import { useCurrency } from "~/hooks";
import { currencies } from "~/tools";

const UnorderedList = styled.ul`
  list-style-position: inside;
  list-style-type: "- ";
`;

export function BudgetsPreviewMessage() {
  return (
    <FlexContainer
      direction="column"
      alignItems="start"
      rowGap={theme.spacing.spacing08}
    >
      <section>
        <h2>Forecast exceeded budget</h2>
        <UnorderedList>
          <li>3 Organizational Units</li>
          <li>2 applications</li>
          <li>4 environments</li>
        </UnorderedList>
      </section>
      <section>
        <h2>Costs exceeded budget</h2>
        <UnorderedList>
          <li>3 Organizational Units</li>
          <li>2 applications</li>
          <li>4 environments</li>
        </UnorderedList>
      </section>
      <section>
        <div>
          Link to estate records:{" "}
          <Text tag="span" color={theme.color.text.info}>
            https://mca.nordcloudapp.com/estate
          </Text>
        </div>
        <div>
          Link to history details:{" "}
          <Text tag="span" color={theme.color.text.info}>
            https://mca.nordcloudapp.com/workflows?tab=1
          </Text>
        </div>
      </section>
    </FlexContainer>
  );
}

export function CostsPreviewMessage() {
  const { currency } = useCurrency();
  const currencySymbol =
    currencies.find((curr) => curr.code === currency)?.symbol ?? "$";

  return (
    <FlexContainer
      direction="column"
      alignItems="start"
      rowGap={theme.spacing.spacing08}
    >
      <section>
        <h2>Total Monthly Costs</h2>
        <p>{currencySymbol}329982</p>
      </section>
      <section>
        <h2>Total Monthly Forecast</h2>
        <p>{currencySymbol}229982</p>
      </section>
      <section>
        <h2>Cost by providers</h2>
        <UnorderedList>
          <li>{currencySymbol}12 AWS</li>
          <li>{currencySymbol}311 AZURE</li>
          <li>{currencySymbol}211 GCP</li>
          <li>{currencySymbol}3211 IBMCLOUDS</li>
          <li>{currencySymbol}4211 OPENSHIFT</li>
        </UnorderedList>
      </section>
      <section>
        <h2>Cost by categories</h2>
        <UnorderedList>
          <li>{currencySymbol}123 Compute</li>
          <li>{currencySymbol}423 Database</li>
          <li>{currencySymbol}323 Storage</li>
          <li>{currencySymbol}223 Conteiner</li>
          <li>{currencySymbol}923 Analyse</li>
        </UnorderedList>
      </section>
      <section>
        <div>
          Link to estate records:{" "}
          <Text tag="span" color={theme.color.text.info}>
            https://mca.nordcloudapp.com/estate
          </Text>
        </div>
        <div>
          Link to history details:{" "}
          <Text tag="span" color={theme.color.text.info}>
            https://mca.nordcloudapp.com/workflows?tab=1
          </Text>
        </div>
      </section>
    </FlexContainer>
  );
}

export function KpiPreviewMessage() {
  const { currency } = useCurrency();
  const currencySymbol =
    currencies.find((curr) => curr.code === currency)?.symbol ?? "$";

  return (
    <FlexContainer
      direction="column"
      alignItems="start"
      rowGap={theme.spacing.spacing08}
    >
      <section>
        <Text weight="bold" tag="h2">
          Name of Business Context
        </Text>
        <p>Charging Units</p>
      </section>
      <section>
        <Text weight="bold" tag="h2">
          Cost Allocation Coverage KPI
        </Text>
        <p>77% (66,483{currencySymbol}) | Target: &lt; 70%</p>
      </section>
      <section>
        <Text weight="bold" tag="h2">
          Overall Waste KPI
        </Text>
        <p>57% (4,483{currencySymbol}) | Target: &lt; 50%</p>
      </section>
      <section>
        <Text weight="bold" tag="h2">
          Non-Production Environments Shutdown Time KPI
        </Text>
        <p>32% (66,483{currencySymbol}) | Target: &lt; 50%</p>
      </section>

      <section>
        <div>
          Link to estate records:{" "}
          <Text tag="span" color={theme.color.text.info}>
            https://mca.nordcloudapp.com/estate
          </Text>
        </div>
        <div>
          Link to history details:{" "}
          <Text tag="span" color={theme.color.text.info}>
            https://mca.nordcloudapp.com/workflows?tab=1
          </Text>
        </div>
      </section>
    </FlexContainer>
  );
}

export function SavingSuggestionsPreviewMessage() {
  const { currency } = useCurrency();
  const currencySymbol =
    currencies.find((curr) => curr.code === currency)?.symbol ?? "$";

  return (
    <FlexContainer
      direction="column"
      alignItems="start"
      rowGap={theme.spacing.spacing08}
    >
      <section>
        <Text weight="bold" tag="h2">
          Total Monthly Savings
        </Text>
        <UnorderedList>
          <li>{currencySymbol}982</li>
        </UnorderedList>
      </section>
      <section>
        <Text weight="bold" tag="h2">
          Total Monthly Savings by providers
        </Text>
        <UnorderedList>
          <li>{currencySymbol}12 AWS</li>
          <li>{currencySymbol}311 AZURE</li>
          <li>{currencySymbol}211 GCP</li>
          <li>{currencySymbol}3,211 IBMCLOUDS</li>
          <li>{currencySymbol}4,211 OPENSHIFT</li>
        </UnorderedList>
      </section>
      <section>
        <Text weight="bold" tag="h2">
          Total Monthly Savings by categories
        </Text>
        <UnorderedList>
          <li>{currencySymbol}123 Compute</li>
          <li>{currencySymbol}423 Database</li>
          <li>{currencySymbol}323 Storage</li>
          <li>{currencySymbol}223 Container</li>
          <li>{currencySymbol}923 Analyse</li>
        </UnorderedList>
      </section>
      <section>
        <Text weight="bold" tag="h2">
          Total Monthly Savings by polices
        </Text>
        <UnorderedList>
          <li>{currencySymbol}123 Idle</li>
          <li>{currencySymbol}423 Overprovisioned</li>
        </UnorderedList>
      </section>

      <section>
        <div>
          Link to estate records:{" "}
          <Text tag="span" color={theme.color.text.info}>
            https://mca.nordcloudapp.com/estate
          </Text>
        </div>
        <div>
          Link to history details:{" "}
          <Text tag="span" color={theme.color.text.info}>
            https://mca.nordcloudapp.com/workflows?tab=1
          </Text>
        </div>
      </section>
    </FlexContainer>
  );
}

type AnomalyPreviewMessageProps = {
  withLinksToApps?: boolean;
};

export function AnomalyPreviewMessage({
  withLinksToApps = false,
}: AnomalyPreviewMessageProps) {
  const { currency } = useCurrency();
  const currencySymbol =
    currencies.find((curr) => curr.code === currency)?.symbol ?? "$";
  const separator = <>&nbsp;&#124;&nbsp;</>;

  return (
    <FlexContainer
      direction="column"
      alignItems="start"
      rowGap={theme.spacing.spacing08}
    >
      <section>
        <Text weight="bold" tag="h2">
          Anomaly Threshold
        </Text>
        <UnorderedList>
          <li>50%</li>
        </UnorderedList>
      </section>
      <section>
        <Text weight="bold" tag="h2">
          Anomaly Costs
        </Text>
        <UnorderedList>
          <li>
            <ListItemTextUnit>{currencySymbol}66.67</ListItemTextUnit>
            {separator}
            <ListItemTextUnit>2022-10-26</ListItemTextUnit>
            {separator}
            <ListItemTextUnit isLink={withLinksToApps}>
              AutoPatcher
            </ListItemTextUnit>
          </li>
          <li>
            <ListItemTextUnit>{currencySymbol}102.67</ListItemTextUnit>
            {separator}
            <ListItemTextUnit>2022-10-26</ListItemTextUnit>
            {separator}
            <ListItemTextUnit isLink={withLinksToApps}>
              AutoBackup
            </ListItemTextUnit>
          </li>
        </UnorderedList>
      </section>
      <section>
        <div css={{ whiteSpace: "nowrap" }}>
          Link to applications:
          <Text
            pl={theme.spacing.spacing01}
            tag="span"
            color={theme.color.text.info}
          >
            https://mca.nordcloudapp.com/applications
          </Text>
        </div>
        <div css={{ whiteSpace: "nowrap" }}>
          Link to history details:
          <Text
            pl={theme.spacing.spacing01}
            tag="span"
            color={theme.color.text.info}
          >
            https://mca.nordcloudapp.com/workflows?tab=1
          </Text>
        </div>
      </section>
    </FlexContainer>
  );
}

type ListItemTextUnitProps = {
  isLink?: boolean;
};

const ListItemTextUnit = styled.span<ListItemTextUnitProps>`
  display: inline-block;
  min-width: 5.5rem;
  padding-left: ${theme.spacing.spacing01};

  ${({ isLink }) =>
    isLink &&
    css`
      color: ${theme.color.text.info};
    `}
`;

export function UnallocatedResourcesPreviewMessage() {
  return (
    <FlexContainer
      direction="column"
      alignItems="start"
      rowGap={theme.spacing.spacing08}
    >
      <section>212 unallocated resources have been identified</section>
      <section>
        <div css={{ whiteSpace: "nowrap" }}>
          Link to estate records:
          <Text
            pl={theme.spacing.spacing01}
            tag="span"
            color={theme.color.text.info}
          >
            https://mca.nordcloudapp.com/estate
          </Text>
        </div>
        <div css={{ whiteSpace: "nowrap" }}>
          Link to history details:
          <Text
            pl={theme.spacing.spacing01}
            tag="span"
            color={theme.color.text.info}
          >
            https://mca.nordcloudapp.com/workflows?tab=1
          </Text>
        </div>
      </section>
    </FlexContainer>
  );
}
