/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChangeEvent, SetStateAction, useState } from "react";
import { When } from "react-if";
import { useDebounce } from "react-use";
import {
  FlexContainer,
  Input,
  MultipleSelect,
  SelectButton,
  theme,
} from "@nordcloud/gnui";
import { noop } from "~/tools";
import { Separator } from "../Styled";
import { Mode } from "./types";

type Props = {
  allowModeChange?: boolean;
  defaultMode?: Mode;
  onModeChange?: (mode: Mode) => void;
  showSearchBar?: boolean;
  onSetSearch: (value: SetStateAction<string>) => void;
};

export function FilterHeader({
  allowModeChange = false,
  defaultMode = Mode.INCLUDE,
  onModeChange = noop,
  showSearchBar = false,
  onSetSearch,
}: Props) {
  const [mode, setMode] = useState(defaultMode);
  const [input, setInput] = useState("");

  useDebounce(
    () => {
      if (input.length !== 1) {
        onSetSearch(input);
      }
    },
    300,
    [input]
  );

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value.toLowerCase());
  };

  const handleModeChange = (selectedMode: Mode) => {
    setMode(selectedMode);
    onModeChange(selectedMode);
  };

  return (
    <>
      <FlexContainer pb={theme.spacing.spacing03} justifyContent="flex-start">
        <When condition={allowModeChange}>
          <MultipleSelect
            size="small"
            css={{
              alignContent: "right",
              marginRight: theme.spacing.spacing03,
            }}
          >
            <SelectButton
              isActive={mode === Mode.INCLUDE}
              name="include"
              value="1"
              labelText="Include"
              style={{ width: "5rem" }}
              onClick={() => handleModeChange(Mode.INCLUDE)}
            />
            <SelectButton
              name="exclude"
              value="0"
              labelText="Exclude"
              isActive={mode === Mode.EXCLUDE}
              style={{ width: "5rem" }}
              onClick={() => handleModeChange(Mode.EXCLUDE)}
            />
          </MultipleSelect>
        </When>
        <When condition={showSearchBar}>
          <span css={{ flexGrow: 1 }}>
            <Input
              small
              type="text"
              placeholder="Search"
              onChange={onSearchChange}
            />
          </span>
        </When>
      </FlexContainer>
      <Separator />
    </>
  );
}
