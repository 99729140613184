/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  GcpBillingDataDocument,
  GcpBillingDataQuery,
  GcpBillingDataQueryVariables,
  useRemoveGcpBillingConfigMutation,
} from "~/generated/graphql";

export function useDeleteGcpBillingData() {
  const [deleteBillingDataItem, { client, ...mutationState }] =
    useRemoveGcpBillingConfigMutation();

  const removeGcpBillingData = async (data: { onboardedOrgId: string }) => {
    const { data: removeResponse } = await deleteBillingDataItem({
      variables: { onboardedOrgId: data.onboardedOrgId },
    });

    if (removeResponse?.removeGcpBillingConfig) {
      client.cache.updateQuery<
        GcpBillingDataQuery,
        GcpBillingDataQueryVariables
      >(
        {
          query: GcpBillingDataDocument,
        },
        (billingData) => ({
          gcpBillingData: (billingData?.gcpBillingData ?? []).filter(
            (item) => item?.onboardedOrgId !== data.onboardedOrgId
          ),
        })
      );
    }
  };

  return [removeGcpBillingData, mutationState] as const;
}
