/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  AddBusinessContextMutationVariables,
  BusinessContextsDocument,
  BusinessContextsQuery,
  BusinessContextsQueryVariables,
  RemoveBusinessContextMutationVariables,
  useAddBusinessContextMutation,
  useRemoveBusinessContextMutation,
} from "~/generated/graphql";
import { isNotNil, removeFromApolloCache } from "~/tools";

export function useAddBusinessContext() {
  const [
    updateFn,
    {
      client: { cache },
      ...state
    },
  ] = useAddBusinessContextMutation();

  const addBusinessContext = async (
    variables: AddBusinessContextMutationVariables
  ) => {
    const { data } = await updateFn({ variables });
    const addedBusinessContext = data?.addBusinessContextV2;

    cache.updateQuery<BusinessContextsQuery, BusinessContextsQueryVariables>(
      { query: BusinessContextsDocument, variables: { limit: 100, page: 0 } },
      (businessContextData) => ({
        businessContexts: {
          businessContexts: [
            addedBusinessContext,
            ...(businessContextData?.businessContexts?.businessContexts ?? []),
          ].filter(isNotNil),
          ...businessContextData?.businessContexts,
        },
      })
    );

    return addedBusinessContext;
  };

  return [addBusinessContext, state] as const;
}

export function useRemoveBusinessContext(
  options?: Parameters<typeof useRemoveBusinessContextMutation>[0]
) {
  const [remove, { client, ...rest }] = useRemoveBusinessContextMutation({
    ...options,
  });

  const removeBusinessContext = async (
    variables: RemoveBusinessContextMutationVariables
  ) => {
    const response = await remove({ variables });

    removeFromApolloCache(client.cache, {
      item: `BusinessContext:${variables.id}`,
    });

    return response;
  };

  return [removeBusinessContext, { client, ...rest }] as const;
}
