/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { UnpackNestedValue, useFormState } from "react-hook-form";
import { Text, theme } from "@nordcloud/gnui";
import { WorkflowTriggerType } from "~/generated/graphql";
import { ComponentGroup } from "../../constants";
import { WorkflowFormData, WorkflowFormMode } from "../../types";
import { ComponentHolder } from "./ComponentHolder";
import { useHoldTrigger } from "./hooks/useHoldTrigger";

type Props = {
  formMode: WorkflowFormMode;
  onSettingsClick?: () => void;
  initialValues?: UnpackNestedValue<WorkflowFormData>["scheduler"];
  isEmpty?: boolean;
  type?: WorkflowTriggerType;
};

export function TriggerHolder({
  formMode,
  onSettingsClick,
  initialValues,
  isEmpty = false,
  type,
}: Props) {
  const { errors } = useFormState<WorkflowFormData>();
  const {
    handleDrop,
    handleRemove,
    description,
    tagText,
    tagTextColor,
    title,
    iconName,
  } = useHoldTrigger({ initialValues, type });

  return (
    <ComponentHolder
      title={title}
      tooltipCaption={description}
      timelineIcon="thunder"
      timelineLabel="Trigger"
      formMode={formMode}
      color={theme.color.support.lightGreen}
      componentIcon={iconName}
      onSettingsClick={onSettingsClick}
      componentGroup={ComponentGroup.TRIGGER}
      displayPlaceholder={isEmpty}
      onDrop={handleDrop}
      onRemoveClick={handleRemove}
      hasError={errors["triggerComponents"] != null}
    >
      <Text color={tagTextColor} size="sm">
        {tagText}
      </Text>
    </ComponentHolder>
  );
}
