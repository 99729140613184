/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { useCloudAccountCostsByProviderQuery } from "~/generated/graphql";
import { isNil } from "~/tools";
import { tabDefinitions } from "../CloudAccountList/constants";
import { MovedCloudAccountTotalCosts, Provider, ProviderTab } from "../types";
import { extractMovedCostsForProvider, sortTabData } from "../utils";

export function useCostsByProvider(movedCosts: MovedCloudAccountTotalCosts) {
  const { data, loading, error } = useCloudAccountCostsByProviderQuery({
    skip: Object.values(movedCosts).some(isNil),
  });

  const tabs = useMemo(() => {
    const costsByProvider =
      data?.cloudAccountCostsByProvider?.costsByProvider ?? [];

    const result = tabDefinitions.map((tabDefinition) => {
      const tabData = costsByProvider.find(
        (costByProvider) => costByProvider.provider === tabDefinition.name
      );

      const isVMWare = tabData?.provider === Provider.Vmware;
      const cost = isVMWare
        ? tabDefinition.cost
        : tabData?.cost || tabDefinition.cost;

      return tabData
        ? {
            hasWarning: tabData.hasWarning,
            name: tabData.provider as ProviderTab,
            count: tabData.count,
            cost,
            ...extractMovedCostsForProvider(tabData.provider, movedCosts),
          }
        : tabDefinition;
    });

    return sortTabData(result);
  }, [data, movedCosts]);

  return {
    loading,
    error,
    tabs,
  };
}
