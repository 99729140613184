/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  Input,
  Label,
  Spacer,
  theme,
  Text,
  Button,
  FlexContainer,
} from "@nordcloud/gnui";
import { ExternalLink, FormGroup } from "~/components";
import {
  FormData,
  AzureFormFields,
  AzureFormSchema,
  UpdateFormData,
} from "~/views/cloudAccounts/BillingData/azure/validators";
import { FormHint } from "../../components/FormHint";
import {
  azureEnrollmentAccessKeyGuideLink,
  azureEnrollmentNumberGuideLink,
} from "../constants";

type Props = {
  onCloseForm: () => void;
  onSubmit: SubmitHandler<UpdateFormData>;
  defaultValues?: Partial<UpdateFormData>;
  disabledFields?: (keyof FormData)[];
};

export function AzureForm({
  onSubmit,
  onCloseForm,
  defaultValues,
  disabledFields = [],
}: Props) {
  const formMethods = useForm<FormData>({
    resolver: yupResolver(AzureFormSchema),
    defaultValues: { ...defaultValues },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form
        css={{ width: "100%" }}
        id="azure-add-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormHints />
        <Spacer height={theme.spacing.spacing02} />
        <FormGroup error={errors[AzureFormFields.ENROLLMENT_NUMBER]}>
          <Label
            required
            htmlFor={AzureFormFields.ENROLLMENT_NUMBER}
            name="Enrollment Number"
          />
          <Input
            id={AzureFormFields.ENROLLMENT_NUMBER}
            disabled={disabledFields.includes(
              AzureFormFields.ENROLLMENT_NUMBER
            )}
            {...register(AzureFormFields.ENROLLMENT_NUMBER)}
            placeholder="e.g., 12345678"
          />
        </FormGroup>
        <FormGroup error={errors[AzureFormFields.ENROLLMENT_ACCESS_KEY]}>
          <Label
            required
            htmlFor={AzureFormFields.ENROLLMENT_ACCESS_KEY}
            name="Enrollment Access Key"
          />
          <Input
            id={AzureFormFields.ENROLLMENT_ACCESS_KEY}
            disabled={disabledFields.includes(
              AzureFormFields.ENROLLMENT_ACCESS_KEY
            )}
            {...register(AzureFormFields.ENROLLMENT_ACCESS_KEY)}
            placeholder="e.g., eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"
          />
        </FormGroup>
        <FlexContainer gap={theme.spacing.spacing03}>
          <Button type="submit" icon="checkmark">
            Apply
          </Button>
          <Button severity="low" onClick={onCloseForm}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}

function FormHints() {
  return (
    <>
      <FormHint stepNumber={1}>
        <Text>
          Follow this guide to obtain your enrollment number
          <br />
          <ExternalLink href={azureEnrollmentNumberGuideLink}>
            Azure: Manage your enrollment
          </ExternalLink>
        </Text>
      </FormHint>
      <FormHint stepNumber={2}>
        <Text>
          Follow this guide to obtain your enrollment access key
          <br />
          <ExternalLink href={azureEnrollmentAccessKeyGuideLink}>
            Azure api key generation
          </ExternalLink>
        </Text>
      </FormHint>
      <FormHint stepNumber={3}>
        <Text>Fill in the below information to access your billing data.</Text>
      </FormHint>
    </>
  );
}
