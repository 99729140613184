/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled, { css } from "styled-components";
import { theme } from "@nordcloud/gnui";

type Props = {
  hide?: boolean;
};

export const Separator = styled.div<Props>`
  border-top: solid 1px ${theme.color.border.border01};
  grid-area: separator;
  ${handleStylesFromProps};
`;

function handleStylesFromProps(props: Props) {
  return css({
    visibility: props.hide ? "hidden" : "visible",
  });
}
