/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { DateRange, EstatePeriod, Granularity } from "~/components";
import { useQueryState } from "~/hooks";
import { useBillingPeriod } from "~/services/customers";
import { getStartDate, getEndDate } from "../helpers";
import { EstateChartsQueryState } from "../types";
import {
  getBillingPeriod,
  getSelectedBillingPeriod,
  mapEstatePeriod,
} from "./helpers";

export function useEstateChartParameters() {
  const { state, updateQueryState } = useQueryState<EstateChartsQueryState>();
  const { isPreviousBillingPeriod } = useBillingPeriod();

  const [period, setPeriod] = useState<EstatePeriod>(
    getBillingPeriod(isPreviousBillingPeriod, state.billingPeriod)
  );

  const [search, setSearch] = useState("");

  const [range, setRange] = useState<DateRange | undefined>(undefined);

  const granularity = state.granularity ?? Granularity.MONTHS;

  const handleGranularityChange = (granularitySelection: Granularity) => {
    updateQueryState({
      granularity: granularitySelection,
    });
  };

  const handlePeriodChange = (
    selectedPeriod: EstatePeriod,
    selectedRange?: DateRange
  ) => {
    setPeriod(selectedPeriod);
    setRange(selectedRange);

    updateQueryState({
      periodStart: getStartDate(selectedPeriod, selectedRange),
      periodEnd: getEndDate(selectedPeriod, selectedRange),
      billingPeriod: getSelectedBillingPeriod(selectedPeriod),
    });
  };

  useEffect(() => {
    handlePeriodChange(mapEstatePeriod(isPreviousBillingPeriod));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreviousBillingPeriod]);

  return {
    period,
    granularity,
    range,
    handleGranularityChange,
    handlePeriodChange,
    search,
    setSearch,
  };
}
