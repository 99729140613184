/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Cell, Row } from "react-table";
import styled from "styled-components";
import { Button, Checkbox, SVGIcon } from "@nordcloud/gnui";
import { ACTION, IdCell, PopoverControls } from "~/components";
import { ROUTES } from "~/routing/routes";
import { Currency, isNotNil } from "~/tools";
import { AccountStatusIcon } from "../../components/AccountStatusIcon";
import { CloudAccount, ProviderTab } from "../../types";
import {
  getAccountUrl,
  canBeOnboarded,
  isPossibleToUpdateCredentialsV2,
  isKubernetesOrOpenshiftTab,
  hasNoData,
  isVMWare,
  downloadDeployment,
} from "../../utils";
import { getChargeTypeLabel, getProviderIconName } from "../utils";
import { CurrencyCell } from "./CurrencyCell";

const NUMBER_COLUMNS_WIDTH = "9rem";

type Props = {
  chargeTypes: string[];
  provider: ProviderTab;
  currency: Currency;
  selectedAccountsNids: string[];
  isPageSelected: boolean;
  onRemoveHandler: (id: string, name: string) => void;
  onUpdateHandler: (account: CloudAccount) => void;
  onPageSelect: () => void;
  onSelectHandler: (nid: string) => void;
};

export function AccountListColumns({
  onRemoveHandler,
  onUpdateHandler,
  onPageSelect,
  onSelectHandler,
  isPageSelected,
  selectedAccountsNids,
  chargeTypes,
  provider,
  currency,
}: Props) {
  const showTotalCosts = provider !== ProviderTab.VMWARE;

  const providerIconColumn = isKubernetesOrOpenshiftTab(provider)
    ? [
        {
          accessor: "providerIcon",
          width: "1.5rem",
          disableSortBy: true,
          Cell: ({
            row: {
              original: { provider: accountProvider, providerType },
            },
          }: Cell<CloudAccount>) => {
            const iconName = getProviderIconName(accountProvider, providerType);

            return isNotNil(iconName) ? (
              <div css={{ position: "relative" }}>
                <SVGIcon name={iconName} />
              </div>
            ) : null;
          },
        },
      ]
    : [];

  const sharedColumns = [
    {
      accessor: "checkbox",
      width: "1rem",
      disableSortBy: true,
      Header: <Checkbox checked={isPageSelected} onChange={onPageSelect} />,
      Cell: ({
        row: {
          original: { nid },
        },
      }: Cell<CloudAccount>) => (
        <Checkbox
          checked={selectedAccountsNids.includes(nid)}
          onChange={() => onSelectHandler(nid)}
        />
      ),
    },
    {
      accessor: "statusIcon",
      width: "1.5rem",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { cloudStatus },
        },
      }: Cell<CloudAccount>) => {
        return isNotNil(cloudStatus) ? (
          <div css={{ position: "relative" }}>
            <AccountStatusIcon status={cloudStatus} />
          </div>
        ) : null;
      },
    },
    ...providerIconColumn,
    {
      accessor: "Account Name / Account ID",
      Header: <div css={{ minWidth: "10rem" }}>Account Name / Account ID</div>,
      disableSortBy: true,
      Cell: ({ row }: Cell<CloudAccount>) => {
        return (
          <IdCell
            type="cloud-accounts"
            id={row.original.nid}
            name={row.original.displayName ?? row.original.name}
            provider={row.original.provider}
            providerId={row.original.providerId}
            description={row.original.description ?? ""}
            url={getAccountUrl(row.original.providerId, row.original.provider)}
          />
        );
      },
    },
  ];

  const totalCostColumn = showTotalCosts
    ? [
        {
          accessor: "Total Cost",
          Header: <HeaderWrapper>Total Cost</HeaderWrapper>,
          width: NUMBER_COLUMNS_WIDTH,
          disableSortBy: true,
          Cell: getTotalCostCell(currency),
        },
      ]
    : [];

  const actionColumn = {
    Header: "",
    accessor: "actions",
    disableSortBy: true,
    width: "2rem",
    hasLeftBorder: false,
    Cell: ({ row }: Cell<CloudAccount>) => {
      const history = useHistory();
      const actionItems = useMemo(
        () =>
          getActionItems({
            row,
            source: provider,
            history,
            onRemoveHandler,
            onUpdateHandler,
          }),
        [history, row]
      );

      return (
        <PopoverControls
          items={actionItems}
          trigger={<Button severity="low" icon="menu" size="md" />}
        />
      );
    },
  };

  const generatedColumns = !isKubernetesOrOpenshiftTab(provider)
    ? chargeTypes.map((columnName) => ({
        accessor: columnName,
        Header: <HeaderWrapper>{getChargeTypeLabel(columnName)}</HeaderWrapper>,
        width: NUMBER_COLUMNS_WIDTH,
        disableSortBy: true,
        Cell: ({
          row: {
            original: { chargeTypeCosts },
          },
        }: Cell<CloudAccount>) => {
          const cost = chargeTypeCosts?.find(
            (chargeType) => chargeType.chargeType === columnName
          )?.cost;

          return (
            <div css={{ textAlign: "right" }}>
              {parseFloat(cost ?? "0") !== 0 ? (
                <CurrencyCell value={cost} currency={currency} />
              ) : (
                "-"
              )}
            </div>
          );
        },
      }))
    : [];

  return [
    ...sharedColumns,
    ...generatedColumns,
    ...totalCostColumn,
    actionColumn,
  ];
}

const getTotalCostCell = (currency: Currency) => {
  return ({ row }: Cell<CloudAccount>) => {
    if (
      row.original?.chargeTypeCosts?.some((chargeType) =>
        isNotNil(chargeType.cost)
      )
    ) {
      const totalCost = row.original.chargeTypeCosts
        .map((chargeType) => parseFloat(chargeType.cost))
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      return (
        <div css={{ textAlign: "right" }}>
          <CurrencyCell value={totalCost} currency={currency} />
        </div>
      );
    }
    return (
      <div css={{ textAlign: "right" }}>
        <CurrencyCell currency={currency} />
      </div>
    );
  };
};

type ActionItemsProps = {
  row: Row<CloudAccount>;
  source: ProviderTab;
  history: ReturnType<typeof useHistory>;
  onRemoveHandler: (id: string, name: string) => void;
  onUpdateHandler: (account: CloudAccount) => void;
};

const getActionItems = ({
  row,
  source,
  history,
  onRemoveHandler,
  onUpdateHandler,
}: ActionItemsProps) => {
  const deleteAction = {
    label: "Delete Account",
    action: ACTION.DELETE,
    onClick: () => {
      onRemoveHandler(row?.original?.id, row?.original?.name);
    },
  };

  const onboardAction = {
    label: "Onboard Account",
    action: ACTION.EDIT,
    onClick: () => {
      history.push(
        `${ROUTES.accounts.add}?accountId=${row?.original?.id}&source=${source}`
      );
    },
  };

  const updateCredentialsAction = {
    label: "Update Credentials",
    action: ACTION.CUSTOM_TRIGGER,
    onClick: () => {
      onUpdateHandler(row?.original);
    },
  };

  const actions = [deleteAction];

  const { provider, providerType, cloudStatus } = row?.original ?? {};

  if (canBeOnboarded(providerType, cloudStatus)) {
    actions.unshift(onboardAction);
  }

  if (hasNoData(providerType, cloudStatus)) {
    const buttonText = isVMWare(providerType)
      ? "Download Config"
      : "Download YAML";

    const download = {
      label: buttonText,
      action: ACTION.CUSTOM_TRIGGER,
      customIcon: "download",
      onClick: () => {
        downloadDeployment(row?.original);
      },
    };

    actions.unshift(download);
  }

  if (isPossibleToUpdateCredentialsV2(providerType, provider)) {
    actions.unshift(updateCredentialsAction);
  }

  return actions;
};

const HeaderWrapper = styled.div`
  max-width: ${NUMBER_COLUMNS_WIDTH};
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
