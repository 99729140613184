import { getCustomer } from "~/services/customers";
import { CloudAccount } from "~/views/cloudAccounts/types";
import { isVMWare } from "~/views/cloudAccounts/utils";
import {
  DEPLOYMENT_DESCRIPTOR,
  FILE_FORMAT_TYPE,
  METRICS_EXPORTER,
  PROMETHEUS_DEPLOYMENT,
  VMWARE_CONFIG_FILE,
} from "./constants";

export function downloadDeployment(account: CloudAccount) {
  const fileName = getFileName(account);
  const fileContents = generateFileContents(account);

  const element = document.createElement("a");
  element.href = fileContents;
  element.download = fileName;

  document.body.append(element); // Required for this to work in FireFox
  element.click();
}

const getFileName = (account: CloudAccount): string => {
  const isVMWareAccount = isVMWare(account.providerType);

  const fileName = isVMWareAccount ? "config" : account.name;
  const fileFormat = isVMWareAccount
    ? FILE_FORMAT_TYPE.JSON
    : FILE_FORMAT_TYPE.YAML;

  return fileName
    ? `${fileName.replaceAll(/[^\w-]/g, "")}.${fileFormat}`
    : `default.${fileFormat}`;
};

const generateFileContents = (account: CloudAccount) => {
  const deploymentKubernetes = isDeploymentWithMetrics(account)
    ? DEPLOYMENT_DESCRIPTOR.concat(METRICS_EXPORTER, PROMETHEUS_DEPLOYMENT)
    : DEPLOYMENT_DESCRIPTOR;

  const isVMWareAccount = isVMWare(account.providerType);
  const templateFile = isVMWareAccount
    ? VMWARE_CONFIG_FILE
    : deploymentKubernetes;

  const deploymentProperties = getDeploymentProperties(account);

  let finalFile = templateFile;
  for (const key of deploymentProperties.keys()) {
    finalFile = finalFile.replaceAll(
      new RegExp(key, "g"),
      deploymentProperties.get(key) ?? ""
    );
  }

  return `data:application/octet-stream;charset=utf-16le;base64,${btoa(
    finalFile
  )}`;
};

const isDeploymentWithMetrics = (account: CloudAccount): boolean => {
  const metadata = JSON.parse(account.metadata ?? "{}");

  return metadata?.storage !== undefined;
};

const getDeploymentProperties = (account: CloudAccount) => {
  const metadata = JSON.parse(account.metadata ?? "{}");
  const accountId = account?.nid;
  const customer = getCustomer();

  return isVMWare(account.providerType)
    ? new Map([
        [`<CUSTOMER_ID>`, customer?.id ?? ""],
        [`<ACCOUNT_ID>`, accountId],
        [`<STORAGE_NAME>`, metadata?.storageName],
        [`<SAS_TOKEN>`, metadata?.sasToken],
      ])
    : new Map([
        [`<INSERT_SAS_TOKEN>`, metadata?.sasTokens?.primary],
        [`<INSERT_QUEUE_NAME>`, metadata?.queueName],
        [`<INSERT_SCANNER_KEY>`, metadata?.scannerKey],
        [`<INSERT_CUSTOMER_ID>`, customer?.id ?? ""],
        [`<INSERT_CLOUD_ACCOUNT>`, accountId],
        [`<INSERT_PLATFORM>`, account.providerType],
        [`<INSERT_PROVIDER>`, account.provider],
        [`<INSERT_NAMESPACE>`, `nordcloud-scanner`],
        [`<INSERT_STORAGE_NAME>`, metadata?.storage?.storageName],
        [`<INSERT_STORAGE_SAS_TOKEN>`, metadata?.storage?.sasToken],
      ]);
};
