/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then, When } from "react-if";
import {
  ExtendedTooltip,
  FlexContainer,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import { Currency, FormatMoney, isNotNil } from "~/tools";

type Props = {
  currency: Currency;
  movedToK8s?: number;
  movedToOc?: number;
};

export function MovedCosts({ currency, movedToK8s, movedToOc }: Props) {
  return (
    <FlexContainer gap={theme.spacing.spacing01}>
      <If
        condition={
          isNotNil(movedToK8s) &&
          isNotNil(movedToOc) &&
          movedToK8s > 0 &&
          movedToOc > 0
        }
      >
        <Then>
          <ExtendedTooltip
            caption={
              <Text mb={0} size="sm" color={theme.color.text.text04}>
                <FormatMoney value={movedToK8s} currency={currency} /> -
                associated with the Kubernetes clusters have been transferred to
                the Kubernetes tab.
              </Text>
            }
          >
            <SVGIcon name="kubernetes" size="sm" />
          </ExtendedTooltip>
          <ExtendedTooltip
            caption={
              <Text mb={0} size="sm" color={theme.color.text.text04}>
                <FormatMoney value={movedToOc} currency={currency} /> -
                associated with the OpenShift clusters have been transferred to
                the OpenShift tab.
              </Text>
            }
          >
            <SVGIcon name="openshift" size="sm" />
          </ExtendedTooltip>
        </Then>
        <Else>
          <When condition={isNotNil(movedToK8s) && movedToK8s > 0}>
            <ExtendedTooltip
              caption={
                <Text mb={0} size="sm" color={theme.color.text.text04}>
                  <FormatMoney value={movedToK8s} currency={currency} /> -
                  associated with the Kubernetes clusters have been transferred
                  to the Kubernetes tab.
                </Text>
              }
            >
              <FlexContainer gap={theme.spacing.spacing01}>
                <SVGIcon name="kubernetes" size="sm" />
                <Text mb={0} size="sm">
                  <FormatMoney value={movedToK8s} currency={currency} />
                </Text>
              </FlexContainer>
            </ExtendedTooltip>
          </When>
          <When condition={isNotNil(movedToOc) && movedToOc > 0}>
            <ExtendedTooltip
              caption={
                <Text mb={0} size="sm" color={theme.color.text.text04}>
                  <FormatMoney value={movedToOc} currency={currency} /> -
                  associated with the OpenShift clusters have been transferred
                  to the OpenShift tab.
                </Text>
              }
            >
              <FlexContainer gap={theme.spacing.spacing01}>
                <SVGIcon name="openshift" size="sm" />
                <Text mb={0} size="sm">
                  <FormatMoney value={movedToOc} currency={currency} />
                </Text>
              </FlexContainer>
            </ExtendedTooltip>
          </When>
        </Else>
      </If>
    </FlexContainer>
  );
}
