/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as yup from "yup";
import {
  ERROR_IS_REQUIRED,
  ERROR_FILE_TYPE,
} from "../../AddAccount/GcpAccountForm/constants";
import { hasFiles, isJson } from "../../AddAccount/GcpAccountForm/helpers";
import {
  IbmFormField,
  GcpFormField,
  AzureFormField,
  AwsFormField,
} from "./constants";

export const awsCredentialsSchema = yup.object().shape({
  [AwsFormField.EXTERNAL_ID]: yup.string().required("This field is required."),
  [AwsFormField.ARN]: yup.string().required("This field is required."),
});

export const azureCredentialsSchema = yup.object().shape({
  [AzureFormField.APP_ID]: yup.string().required("This field is required."),
  [AzureFormField.PASSWORD]: yup.string().required("This field is required."),
  [AzureFormField.TENANT_ID]: yup.string().required("This field is required."),
});

export const gcpCredentialsSchema = yup.object().shape({
  [GcpFormField.KEY]: yup
    .mixed()
    .test("file", ERROR_IS_REQUIRED, (value: FileList) => {
      return hasFiles(value ?? []);
    })
    .test("fileType", ERROR_FILE_TYPE, (value: FileList) => {
      return hasFiles(value ?? []) && isJson(value[0]);
    }),
});

export const ibmCredentialsSchema = yup.object().shape({
  [IbmFormField.API_KEY]: yup.string().required("This field is required."),
});
