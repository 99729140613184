/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import {
  Box,
  Button,
  FlexContainer,
  ModalConfirm,
  Text,
  theme,
} from "@nordcloud/gnui";
import { CloudAccountsQueryVariables } from "~/generated/graphql";
import { ACTION, PopoverControls } from "~/components";
import { useDisclosure, useQueryState } from "~/hooks";
import { Maybe, isNotEmpty, noop } from "~/tools";
import { useCloudAccountBatchDelete, useCloudAccountSort } from "../hooks";
import {
  CloudAccountsQueryState,
  ProviderTab,
  SearchSortOrder,
} from "../types";
import { ActiveFilters } from "./ActiveFilters";
import { CloudAccountSort } from "./CloudAccountSort";
import { CloudAccountsSearchBox } from "./CloudAccountsSearchBox";
import { useBillingDataStatus } from "./useBillingDataStatus";

type Props = {
  queryChargeType: string | undefined;
  order: SearchSortOrder | undefined;
  chargeTypes: string[];
  searchInput?: Maybe<string>;
  selectedAccountsNids: string[];
  fetchCloudAccountsVariables: CloudAccountsQueryVariables;
  onSetSearchInput: (input: string) => void;
  openFiltersSidebar: () => void;
  openBillingDataSidebar: () => void;
  openAddNewAccountSidebar: () => void;
  onUnselectAllAccounts: () => void;
};

export function CloudAccountFilterBox({
  queryChargeType,
  order,
  chargeTypes,
  searchInput,
  selectedAccountsNids,
  fetchCloudAccountsVariables,
  onSetSearchInput,
  openFiltersSidebar,
  openBillingDataSidebar,
  openAddNewAccountSidebar,
  onUnselectAllAccounts,
}: Props) {
  const {
    state: { provider },
  } = useQueryState<CloudAccountsQueryState>();

  const { isBillingDataInactive } = useBillingDataStatus(provider);

  const {
    isOpen: isConfirmModalOpen,
    open: openConfirmModal,
    close: closeConfirmModal,
  } = useDisclosure();

  const {
    chargeType: selectedChargeType,
    sortDirection,
    onSortSelect,
    onSortOrderChange,
  } = useCloudAccountSort({ chargeType: queryChargeType, order });

  const [batchDeleteCloudAccounts] = useCloudAccountBatchDelete({
    responseParameters: fetchCloudAccountsVariables,
  });

  const handleBatchDelete = async () => {
    await batchDeleteCloudAccounts(selectedAccountsNids);
    closeConfirmModal();
    onUnselectAllAccounts();
  };

  const showBillingDataButton = [
    ProviderTab.AWS,
    ProviderTab.AZURE,
    ProviderTab.GCP,
  ].includes(provider);

  const showSort = provider !== ProviderTab.VMWARE || isNotEmpty(chargeTypes);

  return (
    <Box boxStyle="lightGrey" spacing="spacing04">
      <FlexContainer
        justifyContent="space-between"
        alignContent="center"
        gap={theme.spacing.spacing05}
      >
        <When condition={showSort}>
          <CloudAccountSort
            sortField={selectedChargeType}
            sortDirection={sortDirection}
            chargeTypes={chargeTypes}
            onSortSelect={onSortSelect}
            onSortOrderChange={onSortOrderChange}
          />
        </When>
        <div
          css={{
            flexGrow: 1,
          }}
        >
          <CloudAccountsSearchBox
            searchInput={searchInput ?? ""}
            onSetSearchInput={onSetSearchInput}
          />
        </div>
        <Button severity="low" icon="filter" onClick={openFiltersSidebar}>
          Filters
        </Button>
        <div
          css={{
            height: "2.25rem",
            borderRight: `1px solid ${theme.color.border.border01}`,
          }}
        />
        <FlexContainer gap={theme.spacing.spacing06}>
          <When condition={showBillingDataButton}>
            <Button
              severity="low"
              icon="costSettings"
              color={isBillingDataInactive ? "danger" : undefined}
              onClick={openBillingDataSidebar}
            >
              Billing Data
            </Button>
          </When>
          <Button icon="plus" onClick={openAddNewAccountSidebar}>
            Add new Account
          </Button>
          <PopoverControls
            trigger={<Button severity="low" icon="menu" size="md" />}
            items={[
              {
                label: "Delete accounts",
                action: ACTION.DELETE,
                onClick: isNotEmpty(selectedAccountsNids)
                  ? openConfirmModal
                  : noop,
              },
            ]}
          />
        </FlexContainer>
        <ModalConfirm
          confirm={handleBatchDelete}
          actionLabel="Delete"
          contentLabel="Delete Accounts"
          isOpen={isConfirmModalOpen}
          onClose={closeConfirmModal}
        >
          <Text>Do you want to delete selected accounts?</Text>
        </ModalConfirm>
      </FlexContainer>
      <ActiveFilters />
    </Box>
  );
}
