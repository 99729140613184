/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import {
  FlexContainer,
  theme,
  Text,
  Label,
  Input,
  Button,
} from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";
import { ExternalLink, FormGroup } from "~/components";
import { IbmFormField } from "./constants";
import { Ul } from "./List";
import { StepContainer } from "./StepContainer";
import { UpdateHandlerVariables } from "./types";
import { ibmCredentialsSchema } from "./validators";

type Props = {
  onClose: () => void;
  onSubmit: (variables: UpdateHandlerVariables<Provider.Ibmcloud>) => void;
};

export function IbmUpdateCredentialsForm({ onClose, onSubmit }: Props) {
  const formMethods = useForm<UpdateHandlerVariables<Provider.Ibmcloud>>({
    resolver: yupResolver(ibmCredentialsSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form
        css={{ width: "100%" }}
        id="IBM-cred-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text color={theme.color.text.text02} size="sm">
          To onboard IBM, you must set up an IBM user account for your main
          account as proxy account. The main account is the account with
          resources, and provides you with user access. You use the proxy
          account to create the API Key you need to carry out the scanning of
          resources.
        </Text>
        <FlexContainer
          css={{ width: "100%" }}
          alignItems="flex-start"
          direction="column"
          gap={theme.spacing.spacing03}
        >
          <StepContainer
            stepNumber={1}
            title={
              <Text>
                Sign in to the{" "}
                <ExternalLink href="https://cloud.ibm.com/">
                  IBM Cloud Platform
                </ExternalLink>
                , with the credentials of the main account.
              </Text>
            }
          />
          <StepContainer
            stepNumber={2}
            title={
              <Text>
                On the{" "}
                <ExternalLink href="https://cloud.ibm.com/iam/users">
                  Access(IAM) Users
                </ExternalLink>
                , page, invite the proxy account.
              </Text>
            }
          />
          <StepContainer
            stepNumber={3}
            title={
              <Text>
                In a separate browser, sign in to{" "}
                <ExternalLink href="https://cloud.ibm.com/">
                  IBM Cloud Platform
                </ExternalLink>{" "}
                with proxy account. Then on the{" "}
                <ExternalLink href="https://cloud.ibm.com/notifications">
                  IBM Cloud Notication
                </ExternalLink>{" "}
                page, accept the invitation you sent from the main account.
              </Text>
            }
          />
          <StepContainer
            stepNumber={4}
            title={
              <Text>
                On the Users page of the main account, grant{" "}
                <Text weight="medium" tag="span">
                  Reader
                </Text>{" "}
                access and{" "}
                <Text weight="medium" tag="span">
                  Viewer
                </Text>{" "}
                access, to the proxy account in main account page
              </Text>
            }
          >
            <>
              <Ul>
                <li>
                  <Text>
                    Select{" "}
                    <Text weight="medium" tag="span">
                      Manage
                    </Text>
                    , then{" "}
                    <Text weight="medium" tag="span">
                      Access(IAM)
                    </Text>
                    , and then click{" "}
                    <Text weight="medium" tag="span">
                      User
                    </Text>{" "}
                    from the left menu. Select{" "}
                    <Text weight="medium" tag="span">
                      proxy account
                    </Text>
                    , then click{" "}
                    <Text weight="medium" tag="span">
                      Access policies
                    </Text>{" "}
                    on the top tab, and then click{" "}
                    <Text weight="medium" tag="span">
                      Assign access
                    </Text>
                  </Text>
                </li>
                <li>
                  <Text>
                    On Assign access page, select{" "}
                    <Text weight="medium" tag="span">
                      IAM services
                    </Text>
                    , then select{" "}
                    <Text weight="medium" tag="span">
                      All Identity and Access enabled services
                    </Text>
                    . For{" "}
                    <Text weight="medium" tag="span">
                      How do you want to scope the access?
                    </Text>
                    , select{" "}
                    <Text weight="medium" tag="span">
                      All resources
                    </Text>
                    . Then select{" "}
                    <Text weight="medium" tag="span">
                      Viewer
                    </Text>{" "}
                    and{" "}
                    <Text weight="medium" tag="span">
                      Reader
                    </Text>{" "}
                    for{" "}
                    <Text weight="medium" tag="span">
                      Platform access
                    </Text>
                    ,{" "}
                    <Text weight="medium" tag="span">
                      Service access
                    </Text>
                    , and{" "}
                    <Text weight="medium" tag="span">
                      Resource group access
                    </Text>
                    . Then, click Add.
                  </Text>
                </li>
                <li>
                  <Text>
                    Select{" "}
                    <Text weight="medium" tag="span">
                      Account Management
                    </Text>
                    , then{" "}
                    <Text weight="medium" tag="span">
                      All Account management Service
                    </Text>
                    , and then select Viewer access. Then, click Add.
                  </Text>
                </li>
                <li>
                  <Text>
                    Select{" "}
                    <Text weight="medium" tag="span">
                      Cloud foundry
                    </Text>
                    , then select current organization, and then select{" "}
                    <Text weight="medium" tag="span">
                      Auditor
                    </Text>{" "}
                    access for the Organization role, and for the Space role.
                    Click{" "}
                    <Text weight="medium" tag="span">
                      Add
                    </Text>
                    , then click{" "}
                    <Text weight="medium" tag="span">
                      Assign
                    </Text>{" "}
                    on the right.
                  </Text>
                </li>
                <li>
                  <Text>
                    Select{" "}
                    <Text weight="medium" tag="span">
                      Class infrastructure
                    </Text>
                    , then click{" "}
                    <Text weight="medium" tag="span">
                      Manage infrastructure access
                    </Text>{" "}
                    page, and then select view only in Permission sets. Then,
                    click{" "}
                    <Text weight="medium" tag="span">
                      Apply
                    </Text>{" "}
                    in the Permissions tag. Then, select all options in{" "}
                    <Text weight="medium" tag="span">
                      Select type
                    </Text>
                    , and select{" "}
                    <Text weight="medium" tag="span">
                      Enable future access
                    </Text>{" "}
                    in the Devices tag.
                  </Text>
                </li>
              </Ul>
              <Text>
                As a result, on the{" "}
                <Text weight="medium" tag="span">
                  Access Policies
                </Text>{" "}
                tab, you now see multiple{" "}
                <Text weight="medium" tag="span">
                  Viewer
                </Text>{" "}
                roles.
              </Text>
            </>
          </StepContainer>
          <StepContainer
            stepNumber={5}
            title={
              <Text>
                Switch to the page for the proxy account, and create the API
                Key. For more information, see:{" "}
                <ExternalLink href="https://cloud.ibm.com/iam/apikeys">
                  API Keys document
                </ExternalLink>
                .
              </Text>
            }
          />
          <StepContainer
            stepNumber={6}
            title={
              <Label
                required
                name="Provide The API Key From The Proxy Account"
              />
            }
          >
            <FormGroup error={errors[IbmFormField.API_KEY]}>
              <Input
                {...register(IbmFormField.API_KEY)}
                placeholder="e.g. XEogykkgnehnuMKZUEM-abFxjK7uCNF9FOdBR19-KgTMR"
              />
            </FormGroup>
          </StepContainer>
        </FlexContainer>
        <FlexContainer gap={theme.spacing.spacing04}>
          <Button type="submit" icon="checkmark">
            Apply
          </Button>
          <Button severity="low" onClick={onClose}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}
