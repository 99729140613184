/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Sidebar } from "@nordcloud/gnui";
import { HybridSidebarContent } from "../../CloudAccountList/AddNewAccountSidebar/HybridSidebarContent";
import { CloudProviderType, ProviderTab } from "../../types";
import { Account } from "../types";

type Props = {
  account: Account;
  isOpen: boolean;
  onClose: () => void;
};

export function HybridOnboardSidebar({ account, isOpen, onClose }: Props) {
  const providerName =
    account.providerType === CloudProviderType.Kubernetes
      ? "Kubernetes"
      : "Openshift";
  const provider =
    account.providerType === CloudProviderType.Kubernetes
      ? ProviderTab.KUBERNETES
      : ProviderTab.OPENSHIFT;

  return (
    <Sidebar
      title={`Onboard ${providerName} Clusters`}
      width="40%"
      isOpen={isOpen}
      onClick={onClose}
    >
      <HybridSidebarContent
        provider={provider}
        closeSidebar={onClose}
        clusterName={account.name}
        ownerId={account?.owner?.id}
      />
    </Sidebar>
  );
}
