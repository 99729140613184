/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { ChartType, Granularity } from "~/components";
import { costPerEnvMock } from "../../hooks/mocks";
import { getChartGroups, mapChartDataCorrections } from "../helpers";

type Props = {
  granularity: Granularity;
};

export function useEnvironmentsPerType({ granularity }: Props) {
  const { data } = costPerEnvMock;

  const mappedMonthlyData = mapChartDataCorrections(
    data?.chartMonthlyJob?.data
  );

  const costPerEnvChartData = useMemo(
    () =>
      granularity === Granularity.DAYS
        ? mapChartDataCorrections(data?.chartJob?.data)
        : mappedMonthlyData,
    [data, granularity, mappedMonthlyData]
  );

  const costPerEnvTotal = Number(data?.chartMonthlyJob.data.total ?? "0");

  const environments = getChartGroups(
    mappedMonthlyData,
    ChartType.COST_PER_ENVIRONMENT
  );

  return {
    costPerEnvChartData,
    costPerEnvTotal,
    environments,
  };
}
