/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { When } from "react-if";
import { Link } from "react-router-dom";
import { Cell, Row } from "react-table";
import { SVGIcon, Text, theme } from "@nordcloud/gnui";
import { ButtonWrap } from "~/components";
import { DetailData } from "~/components/Details/Detail";
import { SUCCESS_TEXT } from "~/constants";
import { ROUTES } from "~/routing/routes";
import { showSimpleInfo } from "~/services/toast";
import { extendedEncodeURI, isString } from "~/tools";

const OS_IMAGE = [
  "ubuntu",
  "redHat",
  "centOS",
  "amazonLinux",
  "redHat",
  "suse",
  "oracle",
] as const;

const OPERATING_SYSTEM = {
  windows: "windows",
  linux: "linux",
  default: "default",
} as const;

type IconProps = {
  row: Row<DetailData>;
  showActions: boolean;
};

export const KEY_VALUE_COLUMNS = [
  {
    Header: "Key",
    accessor: "key",
    disableSortBy: true,
    width: "40%",
    Cell: ({ row }: Cell<DetailData>) => (
      <Text tag="span">{row.original.label}</Text>
    ),
  },
  {
    Header: "Value",
    accessor: "value",
    disableSortBy: true,
    width: "60%",
    Cell: ({ row }: Cell<DetailData>) => {
      const operatingSystemIcon = check(row);
      const [action, setAction] = React.useState(false);

      return (
        <>
          <When condition={operatingSystemIcon !== "default"}>
            <span css={{ position: "relative" }}>
              <SVGIcon
                data-testid="operatingSystemIcon"
                name={operatingSystemIcon}
                css={{ verticalAlign: "sub", marginRight: "5px" }}
              />
            </span>
          </When>
          <span
            role="button"
            tabIndex={0}
            onMouseEnter={() => setAction(true)}
            onMouseLeave={() => setAction(false)}
          >
            <Text tag="span">{row.original.value}</Text>
            <ActionIcons showActions={action} row={row} />
          </span>
        </>
      );
    },
  },
];

function ActionIcons({ row, showActions }: IconProps) {
  const checkedValue = isString(row.original.value)
    ? extendedEncodeURI(row.original.value)
    : "";

  return (
    <When condition={showActions}>
      <Link to={`${ROUTES.estateRecords.index}?query=${checkedValue}`}>
        <ButtonWrap css={{ display: "inline" }}>
          <SVGIcon
            name="search"
            size="sm"
            css={{
              cursor: "pointer",
              marginLeft: theme.spacing.spacing02,
            }}
          />
        </ButtonWrap>
      </Link>
      <CopyToClipboard
        text={checkedValue}
        onCopy={() => {
          showSimpleInfo(SUCCESS_TEXT.copied);
        }}
      >
        <ButtonWrap css={{ display: "inline" }}>
          <SVGIcon
            name="copy"
            size="sm"
            css={{
              cursor: "pointer",
              marginLeft: theme.spacing.spacing02,
            }}
          />
        </ButtonWrap>
      </CopyToClipboard>
    </When>
  );
}

function check(row: Row<DetailData>) {
  if (row.original.label === "operatingSystem") {
    return mapOperatingSystem(row);
  }
  if (row.original.label === "osImage") {
    return checkOsImage(row);
  }
  return OPERATING_SYSTEM.default;
}

function mapOperatingSystem(row: Row<DetailData>) {
  switch (row.original.value) {
    case "windows":
      return OPERATING_SYSTEM.windows;
    case "linux":
      return OPERATING_SYSTEM.linux;
    default:
      return OPERATING_SYSTEM.default;
  }
}

function checkOsImage(row: Row<DetailData>) {
  if (isString(row.original.value)) {
    const value = row.original.value.toLocaleLowerCase() ?? "";

    const icon = OS_IMAGE.find((index) =>
      value.includes(index.replaceAll(/([A-Z])/g, " $1").toLocaleLowerCase())
    );
    return icon ?? "default";
  }
  return OPERATING_SYSTEM.default;
}
