/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { SortByValue } from "./types";

export const enum PageName {
  UsersAndPermissions = "Users & Permissions",
  GlobalApiKeys = "Global Api Keys",
}

export const SELECT_OPTIONS_USERS_PERMISSIONS = [
  {
    value: SortByValue.UserEmailId,
    label: "Email",
  },
  {
    value: SortByValue.LastLoginDate,
    label: "Last Login Date",
  },
];

export const SELECT_OPTIONS_GLOBAL_KEYS_APIS = [
  {
    value: SortByValue.ApiKeyDescription,
    label: "Description",
  },
  {
    value: SortByValue.CreationDate,
    label: "Creation Date",
  },
  {
    value: SortByValue.ExpirationDate,
    label: "Expiration Date",
  },
];

export const SELECT_OPTIONS = [
  ...SELECT_OPTIONS_USERS_PERMISSIONS,
  ...SELECT_OPTIONS_GLOBAL_KEYS_APIS,
];
