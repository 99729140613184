/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export enum AwsFormField {
  EXTERNAL_ID = "externalId",
  ARN = "arn",
}

export enum AzureFormField {
  APP_ID = "appId",
  PASSWORD = "password",
  TENANT_ID = "tenantId",
}

export enum GcpFormField {
  KEY = "key",
}

export enum IbmFormField {
  API_KEY = "apikey",
}
