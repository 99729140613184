/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import {
  Box,
  MultipleSelect,
  SelectButton,
  Spacer,
  theme,
  BrickLoader,
  Text,
} from "@nordcloud/gnui";
import { UniversalWrap } from "~/components";
import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { showSuccess, showError } from "~/services/toast";
import { AwsAutoForm } from "./AwsAutoForm";
import { AwsManualForm } from "./AwsManualForm";
import { useAddAwsBillingData } from "./hooks/useAddAwsBillingData";
import { FormData } from "./validators";

export function AddBillingData({ onClose }: { onClose: () => void }) {
  const [addBillingDataFormType, setAddBillingDataFormType] =
    useState<FormType>("auto");

  const { addAwsBillingData, error, loading } = useAddAwsBillingData();

  const onSubmit = async (data: FormData) => {
    try {
      await addAwsBillingData(data);
      showSuccess(SUCCESS_TEXT.billingDataAdded);
      onClose();
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  const Form = addBillingDataFormType === "auto" ? AwsAutoForm : AwsManualForm;

  return (
    <Box boxStyle="lightGrey">
      <Text weight="medium">Billing Data Settings</Text>
      <MultipleSelect>
        <SelectButton
          name="auto"
          value="auto"
          labelText="Automatic (Recommended)"
          isActive={addBillingDataFormType === "auto"}
          onClick={() => setAddBillingDataFormType("auto")}
        />
        <SelectButton
          style={{ paddingInline: "6rem" }}
          name="manual"
          value="manual"
          labelText="Manual"
          isActive={addBillingDataFormType === "manual"}
          onClick={() => setAddBillingDataFormType("manual")}
        />
      </MultipleSelect>
      <Spacer height={theme.spacing.spacing04} />
      <UniversalWrap
        loaderProps={{ loading, Component: BrickLoader }}
        errorProps={{ error }}
      >
        <Form showRegionDisclaimer closeForm={onClose} onSubmit={onSubmit} />
      </UniversalWrap>
    </Box>
  );
}

type FormType = "auto" | "manual";
